<template>
     <div>
          <GroupContainer title="Récapitulatif">
               <div>
                    <h6 class="text-muted">Progression moyenne des collaborateurs : </h6>
                    <b-progress height="1.75rem">
                         <b-progress-bar v-if="$store.state.selectedAdminProject" striped :value="progress"
                              :label="`${progress}%`"></b-progress-bar>
                         <b-progress-bar v-else striped :value="0"></b-progress-bar>
                    </b-progress>
               </div>
               <b-row class="mt-2" style="max-width:850px">
                    <b-col>
                         <StatsCard 
                              v-model="saisir"
                              title="Doivent saisir le formulaire"
                              dateLabel="Début de la session : "
                              :date="currentSession"
                              class="saisir mx-auto" 
                         />
                    </b-col>
                    <b-col>
                         <StatsCard 
                              v-model="venir" 
                              title="En attente de la prochaine session"
                              dateLabel="Prochaine session : "
                              :date="nextSession"
                              class="venir mx-auto" 
                         />
                    </b-col>
                    <b-col>
                         <StatsCard v-model="fini" title="Validés" class="fini mx-auto" />
                    </b-col>
               </b-row>
          </GroupContainer>
          <GroupContainer title="PowerBi">
               <template v-slot:header-right>
                    <small class="text-muted">Source Projet</small>
                    <b-button size="sm" class="ml-2 border" @click="powerBi = true" variant="light">
                         <b-icon icon="gear-fill" />
                    </b-button>
               </template>
               <iframe v-if="$store.state.selectedAdminProject && $store.state.selectedAdminProject.powerbisrc"
                    title="V-comp" style="width:100%;height:80vh"
                    :src="$store.state.selectedAdminProject.powerbisrc + '&filterPaneEnabled=false'" frameborder="0"
                    allowFullScreen="true">
               </iframe>
               <div v-else class="text-center h3 pb-5 pt-4 text-muted">
                    <b-iconstack font-scale="1">
                         <b-icon icon="bar-chart-fill" variant="warning" scale="0.75" />
                         <b-icon icon="x-circle" variant="danger" />
                    </b-iconstack>
                    <p class="mt-5">PowerBi absent</p>
                    <!--b-button class="ml-2 border" @click="powerBi = true" variant="light"><b-icon icon="bar-chart-fill" variant="warning" scale="0.75"/> Creation du PowerBi</b-button-->
               </div>
          </GroupContainer>
          <Modal v-model="powerBi" title="Clef API PowerBi">
               <div class="d-flex justify-content-between align-items-center">
                    <div class="border pl-2  rounded"><small>Source =
                              Json.Document(Web.Contents("https://v-comp.groupe-vital.com/api.php?api_key={{$store.state.selectedAdminProject.api_key}}")),</small></div>
                    <div>
                         <b-button class="ml-2" @click="copyPowerBi" variant="outline-primary">
                              <b-icon icon="clipboard" />
                         </b-button>
                    </div>
               </div>
          </Modal>
     </div>
</template>
<script>
import GroupContainer from '/src/components/GroupContainer.vue'
import StatsCard from '/src/components/ProjetManager/General/Stats.vue'
import Modal from '/src/components/Modal.vue'

export default {
     name: "PowerBi",
     components: {
          GroupContainer,
          StatsCard,
          Modal
     },
     data() {
          return {
               show: false,
               powerBi: false
          };
     },
     methods: {
          copyPowerBi() {
               let source = 'Source = Json.Document(Web.Contents("https://v-comp.groupe-vital.com/api.php?api_key=' + this.$store.state.selectedAdminProject.api_key + '")),'
               navigator.clipboard.writeText(source).then(() => {
                    alert("Source du projet copié dans le presse papier")
               }, () => {
                    console.log("error")
               });
          },
     },
     computed: {
          progress() {
               const collabs = this.$store.state.selectedAdminProject.collabs
               return (collabs.reduce((sum, current) => sum + parseInt(current.progress), 0) / collabs.length).toFixed(0)
          },
          saisir() {
               if (!this.$store.state.selectedAdminProject) return []
               return this.$store.state.selectedAdminProject.collabs.filter((collab) => {
                    return collab.statut == "A saisir";
               });
          },
          venir() {
               if (!this.$store.state.selectedAdminProject) return []
               return this.$store.state.selectedAdminProject.collabs.filter((collab) => {
                    return collab.statut == "A venir";
               });
          },
          fini() {
               if (!this.$store.state.selectedAdminProject) return []
               return this.$store.state.selectedAdminProject.collabs.filter((collab) => {
                    return collab.statut == "Validé";
               });
          },
          currentSession() {
               if(!this.$store.state.selectedAdminProject) return
               let today = new Date()
               let startDate = new Date(this.$store.state.selectedAdminProject.dateDebut)
               const delai = this.$store.state.selectedAdminProject.delai

               let days = parseInt((today - startDate) / (1000 * 3600 * 24))
               let current = parseInt(days / (7 * delai))

               startDate.setDate(startDate.getDate() + (current * 7 * delai))
               return startDate.toLocaleDateString("fr")
          },
          nextSession() {
               if(!this.$store.state.selectedAdminProject) return
               let today = new Date()
               let startDate = new Date(this.$store.state.selectedAdminProject.dateDebut)
               const delai = this.$store.state.selectedAdminProject.delai

               let days = parseInt((today - startDate) / (1000 * 3600 * 24))
               let current = parseInt(days / (7 * delai)) + 1

               startDate.setDate(startDate.getDate() + (current * 7 * delai))
               return startDate.toLocaleDateString("fr")
          }
     }
};
</script>
<style scoped>
.saisir {
     background-color: rgb(241, 97, 97);
}

.venir {
     background-color: rgb(91, 127, 228);
}

.fini {
     background-color: rgb(86, 148, 73);
}
</style>