<template>
     <div class="px-2">
          <div>
               <b-button variant="danger" @click="showDelete = true">
                    <b-icon icon="trash"></b-icon> Supprimer le projet
               </b-button>
          </div>
          <div class="text-center">

               <b-avatar v-if="$store.state.selectedAdminProject"
                    :variant="$store.state.selectedAdminProject.imgsrc ? '' : 'info'" icon="briefcase" size="10rem"
                    class="p-2 mb-3" :src="$store.state.selectedAdminProject.imgsrc" />
          </div>
          <div>
               <Input v-if="$store.state.selectedAdminProject" label="Nom du projet" type="text"
                    class="my-2 general-edit" size="sm" v-model="$store.state.selectedAdminProject.title"
                    required></Input>
          </div>
          <b-row>
               <b-col>
                    <Input v-if="$store.state.selectedAdminProject" label="Date de démarrage" type="date"
                         class="my-2 general-edit" size="sm" v-model="$store.state.selectedAdminProject.dateDebut"
                         required></Input>
               </b-col>
               <b-col>
                    <Input v-if="$store.state.selectedAdminProject" label="Délai entre deux saisie" type="number"
                         min="1" max="8" class="my-2 general-edit" size="sm"
                         v-model="$store.state.selectedAdminProject.delai" required></Input>
               </b-col>
          </b-row>
          <b-row>
               <b-col>
                    <Input v-if="$store.state.selectedAdminProject" type="text" class="my-2 general-edit" size="sm"
                         label="Lien PowerBi" v-model="$store.state.selectedAdminProject.powerbisrc" required
                         ></Input>
               </b-col>
               <b-col>
                    <Input v-if="$store.state.selectedAdminProject" type="text" class="my-2 general-edit" size="sm"
                         label="Logo du projet" v-model="$store.state.selectedAdminProject.imgsrc" required
                         ></Input>
               </b-col>
          </b-row>
          <Modal v-if="$store.state.selectedAdminProject" v-model="showDelete" title="Supprimer le projet">
               <div class="text-center py-3">
                    <b-avatar variant="info" :src="$store.state.selectedAdminProject.imgsrc" icon="briefcase"
                         size="10rem" />
                    <h4 class="my-4">{{$store.state.selectedAdminProject.title}}</h4>
               </div>
               <div>
                    <Input v-model="deleteForm" label="Tapper SUPPRIMER pour valider la suppression:" type="text" />
               </div>
               <template v-slot:buttons>
                    <b-button class="mr-2" @click="showDelete=false" variant="outline-danger">Annuler</b-button>
                    <b-button :variant="!deleteForm || deleteForm != 'SUPPRIMER' ? 'secondary':'outline-primary'"
                         @click="deleteProjet" :disabled="!deleteForm || deleteForm != 'SUPPRIMER'">Supprimer
                    </b-button>
               </template>
          </Modal>
     </div>
</template>
  
<script>
import Input from '/src/components/Input.vue'
import Modal from '/src/components/Modal.vue'

export default {
     name: "ProjetSideBar",
     components: {
          Input,
          Modal
     },
     data() {
          return {
               showDelete: false,
               deleteForm: ""
          }
     },
     methods: {
          deleteProjet() {
               if (this.deleteForm != 'SUPPRIMER') return
               this.showDelete = false
               this.axios
                    .get(
                         "/deleteProjet.php?&IDprojet=" +
                         this.$store.state.selectedAdminProject.ID
                    )
                    .then(async (response) => {
                         if (response.data != "ko") {
                              this.$store.dispatch("refreshProjectsAdmin");
                              this.$router.push('/adminProjets')
                         }
                    });
          },
          saveChanges() {
               this.$store.dispatch("saveAdminProjectChanges");
          }
     },
     watch: {
          showDelete() {
               if (!this.showDelete) this.deleteForm = ""
          }
     }
}
</script>

<style scoped>
.b-avatar {
     border: 1px solid rgb(199, 196, 196)
}

.general-edit {
     font-size: 16px
}
</style>