<template>
    <transition name="fade">
        <div v-if="value" class="modal-ext">
            <div class="modal-in">
                <div class="modal-head">
                    <div class="modal-close">
                        <span @click="hide" class="modal-close-button">🗙</span>
                    </div>
                    <div class="modal-title">
                        <h3>{{title}}</h3>
                    </div>
                </div>
                <div class="modal-body">
                    <slot></slot>
                    <div class="modal-button">
                        <slot name="buttons"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
  </template>
  
  <script>
    export default {
        name: "Modal",
        props : [
            "value",
            "title"
        ],
        methods : {
            hide() {
                this.$emit('input', false)
            }
        }
    }
  </script>
  
  <style scoped>
    .modal-ext {
        background-color : rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(3px);
        position: fixed;
        top : 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 999;
    }

    .modal-in {
        background-color: white;
        z-index: 9999;
        max-width: 800px;
        max-height: 800px;
        margin: 5rem auto;
        border-radius : 10px;
        padding: 0 2rem 1rem 2rem;
    }

    .modal-head{
        padding: 15px;
    }
    .modal-title{
        color: inherit;
        text-align: center;
        width: 100%;
        padding: 0.2rem 0;
    }

    .modal-body {
          max-height: 650px;
          overflow-y: auto;
    }
    .modal-close{
        text-align: right;
    }
    .modal-close-button{
        cursor:pointer;
        font-size: 1.25rem;
    }

    .modal-button {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        padding: 1rem 0
    }

    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active{
        transition: opacity 0.03s ease-in;
    }

    .fade-leave-active{
        transition: opacity 0.05s ease-out;
    }
    .fade-enter-to {
        opacity: 1;
    }
  </style>
  