<template>
  <router-view  v-slot="{ Component }" appear>
    <transition name="fade" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>
<script>
export default {
  name: "AdminProjetsRouter",
};
</script>

<style scoped>
.fade-enter-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
