<template>
  <b-container>
    <div style="max-width: 55rem" class="mx-auto">
      <b-input-group class="mt-3 px-2">
        <b-input-group-prepend is-text>
          <b-icon icon="search"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          class="mx-auto"
          type="text"
          v-model="search"
          placeholder="Nom du projet"
        />
        <b-button class="my-auto ml-2" variant="primary" @click="refresh">
          <b-icon icon="arrow-clockwise" />
          <span class="d-none d-md-inline"> Actualiser</span>
        </b-button>
      </b-input-group>
    </div>
    <b-alert v-model="error" variant="danger" class="text-center h4"
      >Une erreur est survenue</b-alert
    >
      <div
        class="text-center p-5"
        v-if="!$store.state.loading && !filteredList.length"
      >
        <h3 id="number">
          {{ search.length ? "Aucun résultat" : "Aucun projet pour le moment" }}
        </h3>
      </div>
      <transition-group
        tag="b-row"
        class="mx-auto mt-1 mt-md-3"
        style="max-width: 55rem"
        name="slide-fade"
        mode="out-in"
      >
        <b-col
          md="6"
          sm="12"
          v-for="projet in filteredList"
          v-bind:key="projet.ID"
          class="py-3 mx-auto"
        >
          <b-card
            class="mb-2 overflow-auto mx-auto"
            style="min-width: 15rem; max-width: 20rem"
            @click="selectProject(projet)"
          >
            <template #header>
              {{ projet.nom }}
            </template>
            <b-row>
              <b-col lg="5" md="12" class="text-center align-middle">
                <b-avatar
                  variant="info"
                  :src="projet.imgsrc"
                  :badge-variant="badgeVariant(projet)"
                  badge-top
                  badge-left
                  icon="briefcase"
                  size="5rem"
                  rounded
                  class="mb-3"
                >
                  <template #badge>
                    <b-icon :icon="badgeIcon(projet)" />
                  </template>
                </b-avatar>
              </b-col>
              <b-col lg="7" md="12">
                <b-card-text>
                  <span
                    >Statut :
                    <strong :class="'text-' + badgeVariant(projet)">{{
                      projet.statut
                    }}</strong></span
                  ><br />
                  <span v-if="projet.dateSaisie"
                    >
                    <span v-if="daysBeforeSaisie(projet.dateSaisie) > 0">
                      A saisir : dans
                      <span style="color: #6495ed; font-weight: bold">
                        {{ daysBeforeSaisie(projet.dateSaisie) }}</span
                      >
                      jour(s)
                    </span>
                    <span v-if="daysBeforeSaisie(projet.dateSaisie) == 0">
                      A saisir :
                      <span style="color: green; font-weight: bold">
                        Aujourd'hui</span
                      >
                    </span>
                    <span v-if="daysBeforeSaisie(projet.dateSaisie) < 0">
                      A saisir :
                      <span style="color: red; font-weight: bold">
                        {{ -daysBeforeSaisie(projet.dateSaisie) }}</span
                      >
                      jour(s) de retard
                    </span>
                  </span>
                </b-card-text>
              </b-col>
            </b-row>
            <template #footer>
              <span>Votre progression :</span>
              <b-progress height="1.5rem" class="mt-1">
                <b-progress-bar
                  :value="projet.progression"
                  :label="`${projet.progression}%`"
                  striped
                  :variant="progressColor(projet.progression)"
                ></b-progress-bar>
              </b-progress>
            </template>
          </b-card>
        </b-col>
      </transition-group>
    <router-view></router-view>
  </b-container>
</template>

<script>
export default {
  name: "Projets",
  data() {
    return {
      show: true,
      search: "",
      error: false,
    };
  },
  beforeMount() {
    this.refresh();
  },
  methods: {
    badgeVariant(projet) {
      if (projet.statut == "A venir") return "secondary";
      else if (projet.statut == "A saisir") return "primary";
      return "success";
    },
    badgeIcon(projet) {
      if (projet.statut == "A venir") return "hourglass-split";
      else if (projet.statut == "A saisir") return "pencil";
      return "check";
    },
    progressColor(value) {
      if (value <= 50) return "danger";
      else if (value <= 60) return "warning";
      else if (value <= 70) return "info";
      else if (value <= 80) return "primary";
      return "success";
    },
    selectProject(projet) {
      this.$store.commit("setSelectedProjectID", projet.ID);
      this.$router.push("/projets/" + projet.ID);
    },
    refresh() {
      this.$store.commit("setProjects", []);
      this.$store.dispatch("refreshProjects");
    },
    daysBeforeSaisie(dateprojet) {
      let today = new Date();
      let date = new Date(dateprojet);
      var diff = {}; // Initialisation du retour
      var tmp = date - today;

      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes

      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes

      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures

      tmp = Math.ceil(tmp / 24); // Nombre de jours restants
      diff.day = tmp;

      return diff.day;
    },
  },
  computed: {
    filteredList() {
      return this.$store.state.projects.filter((projet) => {
        return projet.nom.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: rgba(0, 0, 0, 0.117647) 0 0.17rem 1rem,
    rgba(0, 0, 0, 0.117647) 0 0.17rem 0.7rem;
  transition: 0.15s all ease-in-out;
  transform: perspective(100px) translateZ(0px);
}
.card:hover {
  transform: perspective(100px) translateZ(5px);
  cursor: pointer;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.pointer {
  cursor: pointer;
}

.card-header {
  background-color: #055f83;
  color: white;
  text-align: center;
  font-size: 1.08rem;
}

.b-avatar {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0.1rem 0.6rem,
    rgba(0, 0, 0, 0.2) 0 0.1rem 0.4rem;
}

.slide-fade-enter-active {
  transition: all 0.175s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.175s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}

#number {
  font: 600 25px "Consolas";
  color: orange;
}
</style>