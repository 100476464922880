<template>
  <div>
  <div v-if="$store.state.selectedProject">
    <b-card class="mt-3 mb-5">
      <template #header>
        <b-nav card-header tabs>
          <b-nav-item
            :to="'/projets/' + $route.params.id + '/general'"
            :active="$route.name == 'General'"
            ><b-icon icon="lightning-fill" class="d-none d-md-inline" /> Général</b-nav-item
          >
          <b-nav-item
            v-if="$store.state.selectedProject.statut === 'A saisir'"
            :to="'/projets/' + $route.params.id + '/saisie'"
            :active="$route.name == 'Saisie'"
            ><b-icon icon="pencil" class="d-none d-md-inline" /> Saisie</b-nav-item
          >
          <div class="ml-auto" style="padding: 0">
            <b-button
              to="/projets"
              variant="primary"
              size="sm"
              style="font-size: 0.8rem"
              ><b-icon icon="arrow-left-short" />Retour</b-button
            >
          </div>
        </b-nav>
      </template>
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <router-view />
      </transition>
    </b-card>
  </div>
</div>
</template>
<script>
export default {
  name: "Projet",
  beforeMount() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$store.dispatch("refreshSelectedProject");
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
  }
};
</script>

<style scoped>
.b-overlay {
  height: 100%;
}

.b-card {
  height: 100%;
}

.nav-link {
  padding: 0.5rem 0.75rem 0.25rem 0.75rem;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-leave-to {
  opacity: 0;
}

.fade-enter-from {
  opacity: 1;
}
</style>