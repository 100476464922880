<template>
     <div>
          <div class="mx-2 d-flex justify-content-between align-items-center">
               <div class="py-2">
                    <h5 class="m-0">Création de projet</h5>
                    <p class="m-0 p-0"><small class="text-muted">Vous pourez toujours modifier la configuration du projet après sa création</small></p>
               </div>
               <div>
                    <!--b-button variant="success" class="mr-2">
                         <b-icon icon="clipboard" scale="0.8rem" /> Sauvegarder
                    </b-button-->
                    <b-button variant="primary" @click="$router.push('/adminProjets')">
                         <b-icon icon="arrow-left-short" /> Quitter
                    </b-button>
               </div>
          </div>
          <b-row>
               <b-col cols="12" class="d-xl-none">
                    <GroupContainer>
                         <b-row class="text-center" style="max-width:920px;margin: 0 auto">
                              <b-col v-for="(step, index) in steps" :key="index"
                                   class="d-flex justify-content-center p-0">
                                   <div v-if="index != 0" class="dashed-2"
                                        :class="(index - 1) < currentStep ? 'dashed-2-active' : ''"></div>
                                   <div>
                                        <div class="text-center">
                                             <div class="badge pointer" :class="badgeColor(index)"
                                                  @click="currentStep = index">
                                                  <div v-if="index >= currentStep">{{index + 1}}</div>
                                                  <div v-else>
                                                       <b-icon icon="check"></b-icon>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="d-none d-sm-inline">
                                             <h6 class="my-2" :class="index < currentStep ? 'text-muted' : ''">
                                                  {{step.name}}</h6>
                                        </div>
                                   </div>
                                   <div v-if="index < steps.length - 1" class="dashed-2"
                                        :class="index < currentStep ? 'dashed-2-active' : ''"></div>
                              </b-col>
                         </b-row>
                    </GroupContainer>
               </b-col>
               <b-col class="d-none d-xl-inline" xl="3" lg="4">
                    <GroupContainer>
                         <div v-for="(step, index) in steps" :key="index">
                              <b-row>
                                   <b-col class="text-center" style="max-width: 100px">
                                        <div class="badge pointer" :class="badgeColor(index)"
                                             @click="currentStep = index">
                                             <div v-if="index >= currentStep">{{index + 1}}</div>
                                             <div v-else>
                                                  <b-icon icon="check"></b-icon>
                                             </div>
                                        </div>
                                        <div v-if="index < steps.length - 1" class="dashed"
                                             :class="index < currentStep ? 'dashed-active' : ''"></div>
                                   </b-col>
                                   <b-col>
                                        <h6 :class="index < currentStep ? 'text-muted' : ''">{{step.name}}</h6>
                                        <p class="text-muted">{{step.description}}</p>
                                   </b-col>
                              </b-row>

                         </div>
                    </GroupContainer>
               </b-col>
               <b-col>
                    <GroupContainer>
                         <div class="py-2 text-center">
                              <h3>{{steps[currentStep].name}}</h3>
                              <p>{{steps[currentStep].description}}</p>
                         </div>
                         <div style="min-height : 400px">
                              <div v-if="currentStep == 0">
                                   <b-row>
                                        <b-col class="text-center p-2" md="4">
                                             <div @click="switchLogo" class="pointer">
                                                  <b-avatar badge-left badge-top badge-size="2rem"
                                                       :variant="$store.state.newProject.imgsrc ? '' : 'info'"
                                                       icon="briefcase" size="12rem" class="p-2 mb-3"
                                                       :src="$store.state.newProject.imgsrc" />
                                                  <div>
                                                       <small>Cliquer sur le logo pour le modifier</small>
                                                  </div>
                                             </div>
                                        </b-col>
                                        <b-col class="py-3">
                                             <Input label="Nom du projet : " type="text" class="my-2"
                                                  v-model="$store.state.newProject.title" required/>
                                             <b-row>
                                                  <b-col cols="6">
                                                       <Input label="Date de démarrage : " type="date" class="my-2"
                                                            v-model="$store.state.newProject.date" required />
                                                  </b-col>
                                                  <b-col cols="6">
                                                       <Input label="Délai entre deux saisie : " type="number"
                                                            v-model="$store.state.newProject.delai" min="1" max="8"
                                                            class="my-2" required />
                                                  </b-col>
                                             </b-row>
                                        </b-col>
                                   </b-row>
                              </div>
                              <div v-else-if="currentStep == 1">

                                   <b-container class="py-md-3">
                                        <div>
                                             <div class="mb-3 text-center" v-if="loading">
                                                  <b-spinner label="Loading..."></b-spinner>
                                             </div>
                                             <b-alert class="wobble-hor-bottom" v-for="(a, index) in alerts"
                                                  :key="index" :variant="a.variant" dismissible show>
                                                  {{ a.message }}
                                             </b-alert>
                                             <div class="my-3" v-if="false">
                                                  <b-link target="_blank"
                                                       href="https://v-comp.groupe-vital.com/Fichier_Import_V-COMP.xlsx">
                                                       <b-icon icon="file-earmark-spreadsheet" />
                                                       Télécharger le fichier d'import
                                                  </b-link>
                                             </div>
                                             <b-form-file type="file" @change="onChange" accept=".xlsx" v-model="file"
                                                  browse-text="Sélectionner"
                                                  placeholder="Séléctionner l'import du projet" />
                                             <p><small>Vous pouvez vous passer de la création par import et passer
                                                       directement à la création manuelle</small></p>
                                        </div>
                                   </b-container>
                              </div>
                              <div v-else-if="currentStep == 2">
                                   <b-container class="my-2">
                                        <b-button-toolbar>
                                             <b-button-group size="sm" class="mr-3">
                                                  <b-button variant="primary" @click="showAll">
                                                       <b-icon icon="plus-circle" /> Show all
                                                  </b-button>
                                                  <b-button variant="info" @click="hideAll">
                                                       <b-icon icon="dash-circle" /> Hide all
                                                  </b-button>
                                             </b-button-group>
                                             <b-button size="sm" variant="danger" v-on:click="currentStep--">
                                                  <b-icon icon="trash" /> Recharger le fichier
                                             </b-button>
                                        </b-button-toolbar>
                                        <hr />
                                        <div>
                                             <div>
                                                  <div class="border mt-3 pb-3">
                                                       <b-container class="mb-3 py-2 categories">
                                                            <b-row>
                                                                 <b-col cols="1" class="text-center">
                                                                      <strong>#</strong>
                                                                 </b-col>
                                                                 <b-col cols="7">
                                                                      <strong>Catégorie</strong>
                                                                 </b-col>
                                                                 <b-col cols="1">
                                                                      <strong>Coef</strong>
                                                                 </b-col>
                                                                 <b-col cols="3">
                                                                      <b-button class="float-right" variant="success"
                                                                           size="sm" @click="showCat = true">
                                                                           <b-icon icon="plus-circle" /> Ajouter
                                                                      </b-button>
                                                                 </b-col>
                                                            </b-row>
                                                       </b-container>
                                                       <ul class="custom-list">
                                                            <li v-for="(cat, i) in $store.state.newProject.categories"
                                                                 :key="i" class="mt-1">
                                                                 <b-container>
                                                                      <b-row>
                                                                           <b-col cols="1">
                                                                                <div
                                                                                     class="text-center categories-font py-2">
                                                                                     {{ cat.pos }}
                                                                                </div>
                                                                           </b-col>
                                                                           <b-col cols="7">
                                                                                <b-input type="text"
                                                                                     v-model="cat.Categorie"
                                                                                     class="custom-input categories-font" />
                                                                           </b-col>
                                                                           <b-col cols="1">
                                                                                <b-input type="number"
                                                                                     v-model="cat.coef"
                                                                                     value="5"
                                                                                     class="custom-input categories-font" />
                                                                           </b-col>
                                                                           <b-col cols="3">
                                                                                <b-button-group size="sm">
                                                                                     <b-button
                                                                                          @click="up($store.state.newProject.categories, i)"
                                                                                          :variant="i == 0 ? 'secondary' : 'info'">
                                                                                          <b-icon icon="arrow-up" />
                                                                                     </b-button>
                                                                                     <b-button
                                                                                          @click="down($store.state.newProject.categories, i)"
                                                                                          :variant="
                                                                                            i == $store.state.newProject.categories.length - 1
                                                                                              ? 'secondary'
                                                                                              : 'info'
                                                                                          ">
                                                                                          <b-icon icon="arrow-down" />
                                                                                     </b-button>
                                                                                </b-button-group>
                                                                                <b-button-group size="sm" class="ml-2">
                                                                                     <b-button variant="danger"
                                                                                          @click="removeCategorie(cat)">
                                                                                          <b-icon icon="trash" />
                                                                                     </b-button>
                                                                                </b-button-group>
                                                                                <b-button
                                                                                     @click="cat.toggle = !cat.toggle"
                                                                                     size="sm" pill
                                                                                     :variant="cat.toggle ? 'outline-info' : 'outline-primary'"
                                                                                     class="float-right">
                                                                                     <b-icon :icon="
                                                                                       cat.toggle ? 'caret-down-fill' : 'caret-left-fill'
                                                                                     " />
                                                                                </b-button>
                                                                           </b-col>
                                                                      </b-row>
                                                                 </b-container>
                                                                 <transition name="slide">
                                                                      <div v-if="cat.toggle"
                                                                           class="mx-4 border my-3 pb-3">
                                                                           <b-container
                                                                                class="mb-3 py-2 sous-categories">
                                                                                <b-row>
                                                                                     <b-col cols="1"
                                                                                          class="text-center">
                                                                                          <strong>#</strong>
                                                                                     </b-col>
                                                                                     <b-col cols="7">
                                                                                          <strong>Sous
                                                                                               catégorie</strong>
                                                                                     </b-col>
                                                                                     <b-col cols="1">
                                                                                          <strong>Coef</strong>
                                                                                     </b-col>
                                                                                     <b-col cols="3">
                                                                                          <b-button class="float-right"
                                                                                               variant="success"
                                                                                               size="sm" @click="
                                                                                                 selectedCat = cat.subCategories;
                                                                                                 showScat = true;
                                                                                               ">
                                                                                               <b-icon
                                                                                                    icon="plus-circle" />
                                                                                               Ajouter
                                                                                          </b-button>
                                                                                     </b-col>
                                                                                </b-row>
                                                                           </b-container>
                                                                           <ul class="custom-list">
                                                                                <li v-for="(scat, y) in cat.subCategories"
                                                                                     :key="y" class="mt-1">
                                                                                     <b-container>
                                                                                          <b-row>
                                                                                               <b-col cols="1">
                                                                                                    <div
                                                                                                         class="text-center sous-categories-font py-2">
                                                                                                         {{ cat.pos
                                                                                                         }}.{{ scat.pos
                                                                                                         }}
                                                                                                    </div>
                                                                                               </b-col>
                                                                                               <b-col cols="7">
                                                                                                    <b-input type="text"
                                                                                                         v-model="scat.nom"
                                                                                                         class="custom-input sous-categories-font" />
                                                                                               </b-col>
                                                                                               <b-col cols="1">
                                                                                                    <b-input
                                                                                                         type="number"
                                                                                                         v-model="scat.coef"
                                                                                                         class="custom-input sous-categories-font" />
                                                                                               </b-col>
                                                                                               <b-col cols="3">
                                                                                                    <b-button-group
                                                                                                         size="sm">
                                                                                                         <b-button
                                                                                                              @click="up(cat.subCategories, y)"
                                                                                                              :variant="y == 0 ? 'secondary' : 'info'">
                                                                                                              <b-icon
                                                                                                                   icon="arrow-up" />
                                                                                                         </b-button>
                                                                                                         <b-button
                                                                                                              @click="down(cat.subCategories, y)"
                                                                                                              :variant="
                                                                                                                y == cat.subCategories.length - 1
                                                                                                                  ? 'secondary'
                                                                                                                  : 'info'
                                                                                                              ">
                                                                                                              <b-icon
                                                                                                                   icon="arrow-down" />
                                                                                                         </b-button>
                                                                                                    </b-button-group>
                                                                                                    <b-button-group
                                                                                                         size="sm">
                                                                                                         <b-button
                                                                                                              variant="danger"
                                                                                                              class="ml-2"
                                                                                                              @click="
                                                                                                                removeSubCategorie(cat.subCategories, scat)
                                                                                                              ">
                                                                                                              <b-icon
                                                                                                                   icon="trash" />
                                                                                                         </b-button>
                                                                                                    </b-button-group>
                                                                                                    <b-button
                                                                                                         @click="scat.toggle = !scat.toggle"
                                                                                                         size="sm" pill
                                                                                                         class="float-right"
                                                                                                         :variant="
                                                                                                           scat.toggle ? 'outline-info' : 'outline-primary'
                                                                                                         ">
                                                                                                         <b-icon :icon="
                                                                                                           scat.toggle
                                                                                                             ? 'caret-down-fill'
                                                                                                             : 'caret-left-fill'
                                                                                                         " />
                                                                                                    </b-button>
                                                                                               </b-col>
                                                                                          </b-row>
                                                                                     </b-container>
                                                                                     <transition name="slide">
                                                                                          <div v-if="scat.toggle"
                                                                                               class="mx-4 border my-3">
                                                                                               <b-container
                                                                                                    class="py-2 aptitudes">
                                                                                                    <b-row>
                                                                                                         <b-col cols="1"
                                                                                                              class="text-center">
                                                                                                              <strong>#</strong>
                                                                                                         </b-col>
                                                                                                         <b-col
                                                                                                              cols="7">
                                                                                                              <strong>Aptitude</strong>
                                                                                                         </b-col>
                                                                                                         <b-col
                                                                                                              cols="1">
                                                                                                              <strong>Coef</strong>
                                                                                                         </b-col>
                                                                                                         <b-col
                                                                                                              cols="3">
                                                                                                              <b-button
                                                                                                                   class="float-right"
                                                                                                                   variant="success"
                                                                                                                   size="sm"
                                                                                                                   @click="
                                                                                                                     showApt = true;
                                                                                                                     selectedScat = scat.aptitudes;
                                                                                                                     tmpApt = {
                                                                                                                       nom: '',
                                                                                                                       description: '',
                                                                                                                       type: '',
                                                                                                                     };
                                                                                                                   ">
                                                                                                                   <b-icon
                                                                                                                        icon="plus-circle" />
                                                                                                                   Ajouter
                                                                                                              </b-button>
                                                                                                         </b-col>
                                                                                                    </b-row>
                                                                                               </b-container>
                                                                                               <ul class="custom-list">
                                                                                                    <li v-for="(apt, z) in scat.aptitudes"
                                                                                                         :key="z"
                                                                                                         class="border-top py-2">
                                                                                                         <div>
                                                                                                              <b-container>
                                                                                                                   <b-row>
                                                                                                                        <b-col
                                                                                                                             cols="1">
                                                                                                                             <div
                                                                                                                                  class="aptitudes-font py-2">
                                                                                                                                  {{
                                                                                                                                  cat.pos
                                                                                                                                  }}.{{
                                                                                                                                  scat.pos
                                                                                                                                  }}.{{
                                                                                                                                  apt.pos
                                                                                                                                  }}
                                                                                                                             </div>
                                                                                                                        </b-col>
                                                                                                                        <b-col
                                                                                                                             cols="7">
                                                                                                                             <b-row>
                                                                                                                                  <b-col
                                                                                                                                       cols="12">
                                                                                                                                       <strong>[{{
                                                                                                                                       apt.type[0]
                                                                                                                                       }}]
                                                                                                                                       </strong>
                                                                                                                                       <span
                                                                                                                                            class="aptitudes-font">{{
                                                                                                                                            apt.nom
                                                                                                                                            }}</span>
                                                                                                                                  </b-col>
                                                                                                                             </b-row>
                                                                                                                             <b-row
                                                                                                                                  class="mt-1">
                                                                                                                                  <b-col
                                                                                                                                       cols="12">
                                                                                                                                       <span v-if="apt.description"
                                                                                                                                            class="aptitudes-des-font">{{
                                                                                                                                            apt.description
                                                                                                                                            }}</span>
                                                                                                                                       <span v-else
                                                                                                                                            class="aptitudes-des-font"
                                                                                                                                            style="color: red;font-weight: bold">
                                                                                                                                            Pas
                                                                                                                                            de
                                                                                                                                            descriprion
                                                                                                                                       </span>
                                                                                                                                  </b-col>
                                                                                                                             </b-row>
                                                                                                                        </b-col>
                                                                                                                        <b-col
                                                                                                                             cols="1">
                                                                                                                             <b-input
                                                                                                                                  type="number"
                                                                                                                                  v-model="apt.coef"
                                                                                                                                  class="custom-input sous-categories-font" />
                                                                                                                        </b-col>
                                                                                                                        <b-col
                                                                                                                             cols="3">
                                                                                                                             <b-button-group
                                                                                                                                  size="sm">
                                                                                                                                  <b-button
                                                                                                                                       @click="up(scat.aptitudes, z)"
                                                                                                                                       :variant="
                                                                                                                                         z == 0 ? 'secondary' : 'info'
                                                                                                                                       ">
                                                                                                                                       <b-icon
                                                                                                                                            icon="arrow-up" />
                                                                                                                                  </b-button>
                                                                                                                                  <b-button
                                                                                                                                       @click="down(scat.aptitudes, z)"
                                                                                                                                       :variant="
                                                                                                                                         z == scat.aptitudes.length - 1
                                                                                                                                           ? 'secondary'
                                                                                                                                           : 'info'
                                                                                                                                       ">
                                                                                                                                       <b-icon
                                                                                                                                            icon="arrow-down" />
                                                                                                                                  </b-button>
                                                                                                                             </b-button-group>
                                                                                                                             <b-button-group
                                                                                                                                  size="sm"
                                                                                                                                  class="ml-2">
                                                                                                                                  <b-button
                                                                                                                                       variant="danger"
                                                                                                                                       @click="
                                                                                                                                         removeAptitude(scat.aptitudes, apt)
                                                                                                                                       ">
                                                                                                                                       <b-icon
                                                                                                                                            icon="trash" />
                                                                                                                                  </b-button>
                                                                                                                                  <b-button
                                                                                                                                       variant="primary"
                                                                                                                                       @click="
                                                                                                                                         tmpApt = apt;
                                                                                                                                         showApt = true;
                                                                                                                                       ">
                                                                                                                                       <b-icon
                                                                                                                                            icon="pencil" />
                                                                                                                                  </b-button>
                                                                                                                             </b-button-group>
                                                                                                                        </b-col>
                                                                                                                   </b-row>
                                                                                                              </b-container>
                                                                                                         </div>
                                                                                                    </li>
                                                                                               </ul>
                                                                                          </div>
                                                                                     </transition>
                                                                                </li>
                                                                           </ul>
                                                                      </div>
                                                                 </transition>
                                                            </li>
                                                       </ul>
                                                  </div>
                                             </div>
                                             <b-modal v-model="showCat" centered hide-footer
                                                  title="Ajouter une catégorie" :header-bg-variant="'black'">
                                                  <b-form @submit="addCategorie">
                                                       <b-input v-model="tmpCat" placeholder="Nom de la catégorie"
                                                            required />
                                                       <div class="mt-4 float-right">
                                                            <b-button size="sm" variant="danger" class="mr-2" @click="
                                                              showCat = false;
                                                              tmpCat = '';
                                                            ">
                                                                 Annuler
                                                            </b-button>
                                                            <b-button type="submit" size="sm" variant="success">
                                                                 Ajouter
                                                            </b-button>
                                                       </div>
                                                  </b-form>
                                             </b-modal>
                                             <b-modal v-model="showScat" centered hide-footer
                                                  title="Ajouter une sous-catégorie" :header-bg-variant="'black'">
                                                  <b-form @submit="addSubCategorie">
                                                       <b-input v-model="tmpScat" placeholder="Nom de la sous-catégorie"
                                                            required />
                                                       <div class="mt-4 float-right">
                                                            <b-button size="sm" variant="danger" class="mr-2" @click="
                                                              showScat = false;
                                                              tmpCat = '';
                                                            ">
                                                                 Annuler
                                                            </b-button>
                                                            <b-button type="submit" size="sm" variant="success">
                                                                 Ajouter
                                                            </b-button>
                                                       </div>
                                                  </b-form>
                                             </b-modal>
                                             <b-modal v-model="showApt" centered size="lg" hide-footer
                                                  :title="tmpApt.pos ? 'Modifier une aptitude' : 'Ajouter une aptitude'"
                                                  :header-bg-variant="'black'">
                                                  <b-form @submit="addAptitude" class="px-4">
                                                       <b-row>
                                                            <b-col cols="9">
                                                                 <b-form-group id="fieldset-1"
                                                                      description="Max 70 caracatères"
                                                                      label="Titre de l'apitude">
                                                                      <b-form-input v-model="tmpApt.nom" trim>
                                                                      </b-form-input>
                                                                 </b-form-group>
                                                            </b-col>
                                                            <b-col cols="3">
                                                                 <b-form-group id="fieldset-1"
                                                                      description="Pratique ou Théorique"
                                                                      label="Type de l'apitude">
                                                                      <b-form-select :options="options"
                                                                           v-model="tmpApt.type" required />
                                                                 </b-form-group>
                                                            </b-col>
                                                       </b-row>
                                                       <b-form-group id="fieldset-2" description="Max 150 caracatères"
                                                            label="Description de l'apitude">
                                                            <b-form-textarea v-model="tmpApt.description"
                                                                 placeholder="Ex: ...." required trim rows="4" />
                                                       </b-form-group>
                                                       <div class="mt-4 float-right">
                                                            <b-button size="sm" variant="danger" class="mr-2"
                                                                 @click="showApt = false">
                                                                 Annuler
                                                            </b-button>
                                                            <b-button type="submit" size="sm" variant="success">
                                                                 {{ tmpApt.pos ? "Enregistrer" : "Ajouter" }}
                                                            </b-button>
                                                       </div>
                                                  </b-form>
                                             </b-modal>
                                        </div>
                                   </b-container>
                              </div>
                              <div v-else-if="currentStep == 3">
                                   <b-row>
                                        <b-col>
                                             <h6>Mes collaborateurs</h6>
                                             <div class="px-2 mt-4" style="min-width:300px">
                                                  <Input class="mb-3" type="text" v-model="search2"
                                                       placeholder="🔍 Nom, Prénom du collab" />

                                                  <div v-for="(collab, index) in paginatedList2" :key="index"
                                                       class="py-2"
                                                       :class="index + 1 < paginatedList2.length ? 'border-bottom' : ''"
                                                       draggable @dragstart="startDrag($event, collab, 0)">
                                                       <div class="d-flex align-items-center">
                                                            <div>
                                                                 <b-avatar size="3.5em" class="mb-3 my-auto"
                                                                      variant="info" />
                                                            </div>
                                                            <div class="text-truncate ml-3" style="font-weight: 600">
                                                                 {{collab.Prenom + ' ' + collab.Nom}}
                                                            </div>
                                                            <div class="ml-auto">
                                                                 <b-button variant="link"
                                                                      @click="$store.state.newProject.collabs.push(collab)">
                                                                      <b-icon icon="plus" variant="success" scale="1.75"></b-icon>
                                                                 </b-button>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <b-input-group class="mt-2 ">
                                                       <b-form-group label="Pages :" label-size="sm">
                                                            <b-pagination v-model="currentPage2"
                                                                 :total-rows="filteredList2.length" :per-page="perPage2"
                                                                 :disabled="filteredList2.length <= perPage2"
                                                                 size="sm" />
                                                       </b-form-group>
                                                  </b-input-group>
                                             </div>
                                        </b-col>
                                        <b-col cols>
                                             <h6 class="text-right">Sur le projet</h6>
                                             <div class="px-2 mt-4" style="min-width:300px; min-height:500px"
                                                  @drop="onDrop" @dragover.prevent @dragenter.prevent>
                                                  <Input class="mb-3" type="text" v-model="search3"
                                                       placeholder="Nom, Prénom du collab 🔍" />
                                                  <div style="min-height:250px">
                                                  <div v-for="(collab, index) in paginatedList3"
                                                       :key="index" class="py-2"
                                                       :class="index + 1 < filteredList3.length ? 'border-bottom' : ''"
                                                       @dragend="startDrag($event, collab, 1)" draggable>
                                                       <div class="d-flex align-items-center">
                                                            <div>
                                                                 <b-avatar size="3.5em" class="mb-3 my-auto"
                                                                      variant="info" />
                                                            </div>
                                                            <div class="text-truncate ml-3" style="font-weight: 600">
                                                                 {{collab.Prenom + ' ' + collab.Nom}}
                                                            </div>
                                                            <div class="ml-auto">
                                                                 <b-button variant="link"
                                                                      @click="removeCollab(collab)">
                                                                      <b-icon icon="trash-fill" variant="danger" scale="1.25"></b-icon>
                                                                 </b-button>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div v-if="!$store.state.newProject.collabs.length"
                                                       class="border text-center d-flex justify-content-center align-items-center"
                                                       style="height:300px">
                                                       <div>
                                                            <p><small>Glisser déposer les collaborateur ici</small></p>
                                                       </div>
                                                  </div>
                                                  </div>
                                                  <b-input-group class="mt-2 ">
                                                       <b-form-group label="Pages :" label-size="sm">
                                                            <b-pagination v-model="currentPage3"
                                                                 :total-rows="filteredList3.length" :per-page="perPage2"
                                                                 :disabled="filteredList3.length <= perPage2"
                                                                 size="sm" />
                                                       </b-form-group>
                                                  </b-input-group>
                                             </div>
                                        </b-col>
                                   </b-row>
                              </div>
                              <div v-else-if="currentStep == 4">
                                   <div class="px-5 py-3">
                                        
                                             <b-row style="min-height: 400px">
                                                  <b-col lg="6" class="border-right">
                                                       <h6 class="mb-4">Partager le projet a un manager</h6>
                                                       <small class="text-muted">Le manager doit avoir accès a V-Talent <br>Le mail doit impérativement finir par <strong>@groupe-vital.com</strong></small>
                                                       <Input class="mt-2" placeholder="****@groupe-vital.com" type="mail" v-model="tmp"></Input>
                                                       <div class="mt-4 d-flex justify-content-center">
                                                            <b-button variant="outline-success" @click="add">Ajouter au projet</b-button>
                                                       </div>
                                                  </b-col>
                                                  <b-col lg="6">
                                                       <h6 class="mb-4 text-right">Managers du projet</h6>
                                                       <div v-for="(manager, index) in $store.state.newProject.managers"
                                                            :key="index" class="py-2"
                                                            :class="index + 1 < $store.state.newProject.managers.length ? 'border-bottom' : ''">
                                                            <div class="d-flex align-items-center">
                                                                 <div>
                                                                      <b-avatar size="3.5em" class="mb-3 my-auto"
                                                                           variant="info" />
                                                                 </div>
                                                                 <div class="text-truncate ml-3" style="font-weight: 600">
                                                                      {{manager}}
                                                                 </div>
                                                                 <div class="ml-auto">
                                                                      <b-button variant="link"
                                                                           @click="remove(manager)">
                                                                           <b-icon icon="trash-fill" variant="danger" scale="1.25"></b-icon>
                                                                      </b-button>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </b-col>
                                             </b-row>
                                        
                                   </div>
                              </div>
                         </div>
                         <div class="d-flex justify-content-end pb-3">
                              <b-button v-if="currentStep > 0" @click="previous" variant="outline-primary" class="mr-3">
                                   <b-icon icon="arrow-left-short"></b-icon> Précédent
                              </b-button>
                              <b-button v-if="currentStep < steps.length - 1" @click="next" variant="info">
                                   <b-icon icon="arrow-right-short"></b-icon> Suivant
                              </b-button>
                              <b-button v-if="currentStep == steps.length - 1" variant="success" @click="create">
                                   <b-icon icon="check"></b-icon> Créer le projet
                              </b-button>
                         </div>
                    </GroupContainer>
               </b-col>
          </b-row>
          <Modal v-model="showImg" title="Ajouter un logo a votre projet" class="text-center">
               <b-avatar badge-left badge-top badge-size="2rem" :variant="tmpImgSrc ? '' : 'info'" icon="briefcase"
                    size="16rem" class="p-2 mb-3" :src="tmpImgSrc" />
               <Input label="Url du logo : " type="text" size="sm" class="my-2" v-model="tmpImgSrc" required />
               <template v-slot:buttons>
                    <b-button class="mr-2" @click="showImg=false" variant="outline-danger">
                         Annuler</b-button>
                    <b-button variant="outline-primary" @click="$store.state.newProject.imgsrc=tmpImgSrc;showImg=false">
                         Valider</b-button>
               </template>
          </Modal>
     </div>
</template>

<script>
//import Categories from '../../../components/ProjetManager/Formulaire/Categories.vue'
//import Aptitudes from '../../../components/ProjetManager/Formulaire/Aptitudes.vue'
import GroupContainer from '/src/components/GroupContainer.vue'
import Input from '/src/components/Input.vue'
import Modal from '/src/components/Modal.vue'
import XLSX from "xlsx";

export default {
     name: "addProjet",
     components: {
          //Aptitudes,
          //Categories,
          GroupContainer,
          Input,
          Modal
     },
     data() {
          return {
               tmp: "",
               categories: [],
               file: null,
               data: "",
               wb: null,
               alerts: [],
               loading: false,
               showImg: false,
               tmpImgSrc: "",
               currentStep: 0,
               steps: [
                    {
                         name: "Details",
                         description: "Description du projet"
                    },
                    {
                         name: "Import Formulaire",
                         description: "Création du formulaire"
                    },
                    {
                         name: "Formulaire",
                         description: "Création du formulaire"
                    },
                    {
                         name: "Collaborateurs",
                         description: "Ajouter les collaborateurs"
                    },
                    {
                         name: "Managers",
                         description: "Partagez votre projet"
                    }/*,
                    {
                         name: "Fin",
                         description: "Vérifier et finaliser"
                    }*/
               ],
               search2: "",
               currentPage2: 1,
               search3: "",
               currentPage3: 1,
               perPage2: 4,
               selected: {},
               tmpCollab: null,
               options: [
                    {
                         value: "Pratique",
                         text: "Pratique",
                    },
                    {
                         value: "Théorique",
                         text: "Théorique",
                    },
               ],
               tmpCat: "",
               tmpScat: "",
               tmpApt: {
                    nom: "",
                    description: "",
                    type: "",
               },
               showCat: false,
               showScat: false,
               showApt: false,
               selectedCat: null,
               selectedScat: null,
          };
     },
     mounted() {
          this.$store.dispatch("createNewProject");
     },
     methods: {
          reset() {
               this.$store.state.newProject.categories = [];
               this.currentStep++
               this.file = null;
          },
          addCategorie(e) {
               e.preventDefault();
               this.$store.state.newProject.categories.push({
                    Categorie: this.tmpCat,
                    pos: this.$store.state.newProject.categories.length + 1,
                    subCategories: [],
                    toggle: false,
                    coef: 5
               });
               this.tmpCat = "";
               this.showCat = false;
          },
          removeCategorie(cat) {
               let cats = this.$store.state.newProject.categories;
               let index = cats.indexOf(cat);
               cats.splice(index, 1);
               for (let i = index; i < cats.length; i++) {
                    cats[i].pos--;
               }
          },
          addSubCategorie(e) {
               e.preventDefault();
               this.selectedCat.push({
                    nom: this.tmpScat,
                    pos: this.selectedCat.length + 1,
                    aptitudes: [],
                    coef: 5,
                    toggle: false,
               });
               this.tmpScat = "";
               this.showScat = false;
          },
          removeSubCategorie(cat, scat) {
               let index = cat.indexOf(scat);
               cat.splice(index, 1);
               for (let i = index; i < cat.length; i++) {
                    cat[i].pos--;
               }
          },
          addAptitude(e) {
               e.preventDefault();
               if (!this.tmpApt.pos) {
                    this.selectedScat.push({
                         nom: this.tmpApt.nom,
                         type: this.tmpApt.type,
                         coef: 5, 
                         description: this.tmpApt.description,
                         pos: this.selectedScat.length + 1,
                    });
               }
               this.showApt = false;
          },
          removeAptitude(csat, apt) {
               let index = csat.indexOf(apt);
               csat.splice(index, 1);
               for (let i = index; i < csat.length; i++) {
                    csat[i].pos--;
               }
          },
          sort_array(ar) {
               ar.sort(function (a, b) {
                    return a.pos - b.pos;
               });
          },
          up(ar, index) {
               if (index) {
                    ar[index].pos--;
                    ar[index - 1].pos++;
                    this.sort_array(ar);
               }
          },
          down(ar, index) {
               if (index != ar.length - 1) {
                    ar[index].pos++;
                    ar[index + 1].pos--;
                    this.sort_array(ar);
               }
          },
          startDrag(evt, item, mode) {
               if (!mode) {
                    evt.dataTransfer.dropEffect = 'move'
                    evt.dataTransfer.effectAllowed = 'move'
                    this.tmpCollab = item
               }
               else {
                    let id = this.$store.state.newProject.collabs.indexOf(item)
                    this.$store.state.newProject.collabs.splice(id, 1)
               }

          },
          onDrop() {
               this.$store.state.newProject.collabs.push(this.tmpCollab)
               this.search2 = ""
          },
          next() {
               if (this.currentStep < this.steps.length - 1)
                    this.currentStep++
          },
          previous() {
               if (this.currentStep > 0)
                    this.currentStep--
          },
          badgeColor(n) {
               if (n < this.currentStep)
                    return "badge-valid"
               else if (n == this.currentStep)
                    return "badge-active"
               return ""
          },
          removeCollab(collab) {
               let id = this.$store.state.newProject.collabs.indexOf(collab)
               this.$store.state.newProject.collabs.splice(id, 1)
          },
          switchLogo() {
               this.showImg = true
               this.tmpImgSrc = this.$store.state.newProject.imgsrc
          },
          onChange(event) {
               this.alerts = [];
               this.categories = [];
               this.loading = true;
               this.file = event.target.files ? event.target.files[0] : null;
               if (this.file) {
                    if (
                         this.file.type !=
                         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    )
                         this.alerts.push({
                              message: "Extension de fichier invalide",
                              variant: "danger",
                         });
                    const reader = new FileReader();
                    reader.onload = (e) => {
                         this.wb = XLSX.read(e.target.result, { type: "binary" });
                         if (!this.validateSheetHeaders(0, ["Categorie", "coef"]))
                              this.alerts.push({
                                   message: "Noms de colonnes de la feuille 1 invalides",
                                   variant: "danger",
                              });
                         if (!this.validateSheetHeaders(1, ["Categorie", "SousCategorie", "coef"]))
                              this.alerts.push({
                                   message: "Noms de colonnes de la feuille 2 invalides",
                                   variant: "danger",
                              });
                         if (
                              !this.validateSheetHeaders(2, [
                                   "SousCategorie",
                                   "type",
                                   "Aptitude",
                                   "Description",
                                   "coef"
                              ])
                         )
                              this.alerts.push({
                                   message: "Noms de colonnes de la feuille 3 invalides",
                                   variant: "danger",
                              });
                         let categories = [];
                         let subCategories = [];
                         let aptitudes = [];
                         if (this.alerts.length == 0) {
                              categories = XLSX.utils.sheet_to_json(
                                   this.wb.Sheets[this.wb.SheetNames[0]],
                                   { header: 0 }
                              );
                              subCategories = XLSX.utils.sheet_to_json(
                                   this.wb.Sheets[this.wb.SheetNames[1]],
                                   { header: 0 }
                              );
                              aptitudes = XLSX.utils.sheet_to_json(
                                   this.wb.Sheets[this.wb.SheetNames[2]],
                                   { header: 0 }
                              );
                              for (let scat of subCategories) {
                                   let y = 1;
                                   for (let cat of categories) {
                                        if (typeof cat.subCategories == "undefined")
                                             cat.subCategories = [];
                                        if (cat.Categorie == scat.Categorie) {
                                             cat.subCategories.push({
                                                  nom: scat.SousCategorie,
                                                  toggle: false,
                                                  aptitudes: [],
                                                  coef: scat.coef,
                                                  pos: cat.subCategories.length + 1
                                             });
                                        }
                                        cat.toggle = false;
                                        cat.pos = y;
                                        y++;
                                   }
                              }

                              for (let apt of aptitudes) {
                                   for (let cat of categories) {
                                        for (let scat of cat.subCategories) {
                                             if (apt.SousCategorie == scat.nom) scat.aptitudes.push({
                                                  nom: apt.Aptitude,
                                                  description: apt.Description,
                                                  type: apt.type,
                                                  pos: scat.aptitudes.length + 1,
                                                  coef: apt.coef
                                             });
                                        }
                                   }
                              }

                              for (let cat of categories) {
                                   if (!cat.subCategories.length)
                                        this.alerts.push({
                                             message:
                                                  "Categorie {" + cat.categorie + "} créée mais non utilisé",
                                             variant: "danger",
                                        });
                                   else {
                                        for (let scat of cat.subCategories) {
                                             if (!scat.aptitudes.length)
                                                  this.alerts.push({
                                                       message:
                                                            "Sous-Categorie :'" +
                                                            scat.nom +
                                                            "' créée mais non utilisé",
                                                       variant: "danger",
                                                  });
                                        }
                                   }
                              }
                         }

                         if (this.alerts.length == 0) {
                              this.categories = categories;
                              //this.projet.categories = this.categories;
                              this.$store.state.newProject.categories = this.categories;
                              //this.$store.commit("setNewProject",this.projet);
                              this.currentStep++
                              this.file = null;
                         } else
                              this.alerts.push({
                                   message: "Fichier invalide",
                                   variant: "danger",
                              });
                         this.loading = false;
                    };
                    reader.readAsBinaryString(this.file);
               }
          },
          validateSheetHeaders(sheetNum, shema) {
               return (
                    JSON.stringify(
                         XLSX.utils.sheet_to_json(
                              this.wb.Sheets[this.wb.SheetNames[sheetNum]],
                              { header: 1 }
                         )[0]
                    ) == JSON.stringify(shema)
               );
          },
          showAll() {
               for (let cat of this.categories) {
                    for (let scat of cat.subCategories) scat.toggle = true;
                    cat.toggle = true;
               }
          },
          hideAll() {
               for (let cat of this.categories) {
                    for (let scat of cat.subCategories) scat.toggle = false;
                    cat.toggle = false;
               }
          },
          create() {
               let data = new FormData();
               data.set("newProject", JSON.stringify(this.$store.state.newProject));
               this.axios.post("/createProject.php", data).then((response) => {
                    console.log(response.data);
                    if (response.data != "ko") {
                         this.$store.dispatch("createNewProject");
                         this.$router.push("/adminProjets");
                    }
               });
          },
          add() {
               if(!this.tmp.includes("@groupe-vital.com")) return
               this.$store.state.newProject.managers.push(this.tmp);
               this.tmp = "";
          },
          remove(manager) {
               let array = this.$store.state.newProject.managers;
               array.splice(array.indexOf(manager), 1);
          }
     },
     computed: {
          filteredList2() {
               return this.$store.state.collabs.filter((collab) => {
                    return (collab.Prenom.toLowerCase().includes(this.search2.toLowerCase()) ||
                         collab.Nom.toLowerCase().includes(this.search2.toLowerCase())) && !this.$store.state.newProject?.collabs?.includes(collab);
               });
          },
          paginatedList2() {
               let current = (this.currentPage2 - 1) * this.perPage2
               return this.filteredList2.slice(current, current + this.perPage2)
          },
          filteredList3() {
               return this.$store.state.newProject.collabs.filter((collab) => {
                    return (collab.Prenom.toLowerCase().includes(this.search3.toLowerCase()) ||
                         collab.Nom.toLowerCase().includes(this.search3.toLowerCase()));
               });
          },
          paginatedList3() {
               let current = (this.currentPage3 - 1) * this.perPage2
               return this.filteredList3.slice(current, current + this.perPage2)
          }
     },
};
</script>

<style scoped>
.b-avatar {
     border: 1px solid rgb(199, 196, 196)
}

.badge {
     border-radius: 20%;
     background-color: RGBA(0, 0, 0, 0.1);
     width: 2.5em;
     height: 2.5em;
     padding: 0;
     line-height: 2.5em;
     transition: all 0.5s ease;
     font-size: 19px;
}


.badge-active {
     background-color: rgb(93, 156, 238);
     color: white;
     transition: all 0.5s ease;
}

.badge-valid {
     background-color: rgba(93, 156, 238, 0.2);
     color: rgb(93, 156, 238);
     transition: all 0.5s ease;
}

.dashed {
     border: 0px;
     border-left: 2px dashed rgba(0, 0, 0, 0.1);
     height: 50px;
     width: 1px;
     margin-left: 50%
}

.dashed-active {
     border-left: 4px solid rgba(93, 156, 238, 0.4)
}

.dashed-2 {
     border: 0px;
     border-top: 2px dashed rgba(0, 0, 0, 0.1);
     height: 2px;
     width: 50%;
     margin: 0;
     margin-top: 25px;
}

.dashed-2-active {
     border-top: 4px solid rgba(93, 156, 238, 0.4)
}

ul {
     list-style-type: none;
}
</style>
