<template>
    <GroupContainer title="Managers" :badge="managers.length">
        <!-- MODALS -->

        <Modal v-model="showAdd" title="Ajouter un manager">
            <div>
                <Input class="mb-5" type="email" label="Email du manager :" v-model="managerMail" required />
            </div>
            <template v-slot:buttons>
                <b-button class="mr-2" @click="showAdd=false" variant="outline-danger">Annuler</b-button>
                <b-button variant="outline-success" @click="addManager">Ajouter</b-button>
            </template>
        </Modal>

        <Modal v-model="showDelete" title="Supprimer un manager">
            <div>
                <p class="p-5">Êtes-vous sur de vouloir supprimer ce manager du Projet?</p>
            </div>
            <template v-slot:buttons>
                <b-button class="mr-2" @click="showDelete=false" variant="outline-danger">Annuler</b-button>
                <b-button variant="outline-primary" @click="removeManager" >Supprimer</b-button>
            </template>
        </Modal>
        <!-- MODALS -->

        <!-- TABLE / List -->
        <div class="d-flex">
               <Input  type="text" v-model="search" placeholder="🔍 Mail du manager" size="sm" style="width:100%; max-width:500px;"/>
               <b-button 
                    @click="showAddModal"
                    variant="light"
                    class="ml-2"
                    v-b-tooltip.hover 
                    title="Ajouter un manager"
               >
                    <b-icon  icon="person-plus-fill" scale="1.3rem"/>
               </b-button>
          </div>
        <b-row class="border-bottom list-head mt-3">
            <b-col cols="6">
                MAIL
            </b-col>
            <b-col cols="4">
                ROLE
            </b-col>
            <b-col cols="2">
                ACTIONS
            </b-col>
        </b-row>
        <div v-if="paginationList.length" >
            <div v-for="(manager, index) in paginationList" :key="manager.ID"
                :class="index < paginationList.length - 1 ? 'border-bottom' : ''">
                <b-row class="py-2">
                    <b-col cols="6" class="text-truncate my-auto">
                        <b-avatar variant="warning" badge-variant="dark" class="mr-2">{{manager.email[0] + manager.email[1]}}
                            <template #badge><b-icon icon="asterisk"></b-icon></template>
                        </b-avatar> {{
                        manager.email }}
                    </b-col>
                    <b-col cols="4" class="text-truncate my-auto">
                        Administrateur
                    </b-col>
                    <b-col cols="2" class="my-auto">
                        <b-button variant="link" @click="showDeleteModal(manager)" v-b-tooltip.hover 
                    title="Supprimer le manager">
                            <b-icon icon="trash-fill" variant="danger" scale="1.2"/>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div v-else>
            <div id="number">
                <h1><strong>Ooops!</strong></h1>
                <p>Aucun résultat</p>
            </div>
        </div>
        <!-- TABLE / LIST -->

        <!-- PAGINATION -->
        <b-row class="mt-3">
            <b-pagination class="mt-auto mr-5" size="sm" v-model="currentPage" :total-rows="filteredList.length" :per-page="perPage" />
        </b-row>
        <!-- PAGINATION -->
    </GroupContainer>
</template>
  
  <script>
    import GroupContainer from '/src/components/GroupContainer.vue'
    import Modal from '/src/components/Modal.vue'
    import Input from '/src/components/Input.vue'

    export default {
        name: "Managers",
        components : {
            GroupContainer,
            Modal,
            Input
        },
        props : [
            "managers"
        ],
        data(){
            return {
                search : "",

                showAdd : false,
                managerMail : "",

                showDelete : false,
                manager : {},

                currentPage : 1,
                perPage : 7
            }
        },
        methods : {
            showAddModal(){
                this.showAdd = true
            },
            showDeleteModal(manager){
                this.showDelete = true
                this.manager = manager
            },
            addManager() {
                let data = new FormData();
                data.set("manager", this.managerMail);
                this.axios
                    .post(
                    "/addManager.php?ID=" + this.$store.getters.selectedAdminProject.ID,
                    data
                    )
                    .then((response) => {
                    console.log(response.data);
                    if (response.data != "ko") {
                        this.$store.dispatch("refreshSelectedAdminProject");
                        this.showAdd = false;
                        this.managerMail = "";
                    }
                    });
            },
            removeManager() {
                this.axios
                    .get(
                    "/removeManager.php?email=" +
                        this.manager.email +
                        "&IDprojet=" +
                        this.$store.getters.selectedAdminProject.ID
                    )
                    .then(async (response) => {
                    if (response.data != "ko") {
                        this.$store.dispatch("refreshSelectedAdminProject");
                        this.showDelete = false;
                        this.manager = {}
                    }
                    });
            },
        },
        computed : {
            paginationList() {
                return this.filteredList.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
                );
            },
            filteredList() {
                return this.managers.filter((m) => {
                    return m.email.includes(this.search);
                });
            },
        }
    }
  </script>

  <style scoped>
    .list-head {
        color: rgb(161, 165, 183);
        font-weight : 600;
        font-size: 14px;
        padding: 1px
    }

    #number {
        font: 400 25px "Consolas";
        text-align: center;
        margin:15px
    }
  </style>