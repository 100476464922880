<template>
    <div class="background" :class="value < 1 ? 'dangerBack' : ''">
        <div class="relative"><div class="text-truncate d-inline" v-if="label">{{label + ' : '}}</div>{{value}}%</div>
        <div class="bar" :style="'height:30px;width : ' + value + '%'" :class="getColor"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ProgressBar",
    props : [
      "value",
      "label"
    ],
    computed: {
        getColor(){
            if(this.value <= 40) return "danger"
            else if (this.value <= 60) return "warning"
            else if (this.value <= 80) return "primary"
            return "success"
        }
    }
  }
  </script>
  
  <style scoped>
 .relative{
    position: absolute;
    height:30px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 12px;
 }

 .background {
    background-color: rgb(228, 224, 224);
    border-radius: 5px;
    width:100%;
 }

 .bar {
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
    border-radius: 5px;
    width: 100%
 }

 .primary{
    background-color: #007bff6c!important;
 }
 .warning {
    background-color: #ffae006c!important;
 }

 .danger {
    background-color: #ff00006c!important;
 }
 .dangerBack {
    background-color: #ff00003d!important;
 }

 .success {
    background-color: #1bc5186c!important;
 }
  </style>
  