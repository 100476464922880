<template>
     <div v-if="$store.state.selectedAdminProject">
          <b-row>
               <b-col xs="12" md="6">
                    <Collaborateurs :collabsProjet="$store.state.selectedAdminProject.collabs"
                         :collabsAll="$store.state.collabs" />
               </b-col>
               <b-col>
                    <Managers :managers="$store.state.selectedAdminProject.managers" />
               </b-col>
          </b-row>
     </div>
</template>
<script>
import Managers from '/src/components/ProjetManager/Membres/Managers.vue'
import Collaborateurs from '/src/components/ProjetManager/Membres/Collaborateurs.vue'

export default {
     name: "Membres",
     components: {
          Managers,
          Collaborateurs
     },
     data() {
          return {
               show: false,
               show2: false,
               show3: false,
               show4: false,
               show5: false,
               search: "",
               search2: "",
               search3: "",
               search4: "",
               error: false,
               currentPage: 1,
               perPage: 5,
               currentPage2: 1,
               perPage2: 5,
               currentPage3: 1,
               perPage3: 5,
               collabtmp: null,
               memail: "",
               manager: false,
          };
     },
     methods: {
          statutColor(collab) {
               if (collab.statut == "A saisir") return "saisir";
               else if (collab.statut == "A venir") return "venir";
               return "fini";
          },
          statutIcon(collab) {
               return collab;
          },
          selectCollab(collab) {
               this.$store.commit("setSelectedCollabID", collab.ID);
               this.show5 = true;
          },
          addCollab(collab) {
               let data = new FormData();
               data.set("collab", JSON.stringify(collab));
               console.log("coucou")
               this.axios
                    .post(
                         "/addCollab.php?ID=" + this.$store.getters.selectedAdminProject.ID,
                         data
                    )
                    .then((response) => {
                         if (response.data != "ko") {
                              this.$store.dispatch("refreshSelectedAdminProject");
                              this.show = false;
                         }
                    });
          },
          removeCollab(collab, remove) {
               this.collabtmp = collab;
               if (remove) {
                    this.axios
                         .get(
                              "/removeCollab.php?ID=" +
                              collab.ID +
                              "&IDprojet=" +
                              this.$store.getters.selectedAdminProject.ID
                         )
                         .then(async (response) => {
                              if (response.data != "ko") {
                                   this.$store.dispatch("refreshSelectedAdminProject");
                                   this.show3 = false;
                              }
                         });
               } else this.show3 = true;
          },
          progressColor(value) {
               if (value <= 50) return "danger";
               else if (value <= 60) return "warning";
               else if (value <= 70) return "info";
               else if (value <= 80) return "primary";
               return "success";
          }
     },
     computed: {
          paginationList() {
               return this.filteredList.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
               );
          },
          paginationList2() {
               return this.filteredList2.slice(
                    (this.currentPage2 - 1) * this.perPage2,
                    this.currentPage2 * this.perPage2
               );
          },
          saisir() {
               return this.$store.state.selectedAdminProject.collabs.filter((collab) => {
                    return collab.statut == "A saisir";
               });
          },
          venir() {
               return this.$store.state.selectedAdminProject.collabs.filter((collab) => {
                    return collab.statut == "A venir";
               });
          },
          fini() {
               return this.$store.state.selectedAdminProject.collabs.filter((collab) => {
                    return collab.statut == "Validé";
               });
          },
          filteredList() {
               return this.$store.state.selectedAdminProject.collabs.filter((collab) => {
                    return (
                         (collab.Nom.toLowerCase().includes(this.search.toLowerCase()) ||
                              collab.Prenom.toLowerCase().includes(this.search.toLowerCase()))
                    );
               });
          },
          filteredList2() {
               return this.$store.state.collabs.filter((collab) => {
                    return (
                         collab.Nom.toLowerCase().includes(this.search2.toLowerCase()) ||
                         collab.Prenom.toLowerCase().includes(this.search2.toLowerCase())
                    );
               });
          },
     },
};
</script>

<style scoped>
.saisir {
     background-color: #cce5ff;
}

.venir {
     background-color: #fff3cd;
}

.fini {
     background-color: #d4edda;
}

.slide-fade-enter-active {
     transition: all 0.2s ease-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
     transform: translateY(-50px);
     opacity: 0;
}
</style>