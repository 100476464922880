import { render, staticRenderFns } from "./GroupContainer.vue?vue&type=template&id=6043d2dc&scoped=true&"
import script from "./GroupContainer.vue?vue&type=script&lang=js&"
export * from "./GroupContainer.vue?vue&type=script&lang=js&"
import style0 from "./GroupContainer.vue?vue&type=style&index=0&id=6043d2dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6043d2dc",
  null
  
)

export default component.exports