<template>
     <div>
          <div class="mx-2 d-flex justify-content-between align-items-center">
               <div class="py-2">
                    <h5 class="m-0">Accueil manager</h5>
                    <p class="m-0 p-0"><small class="text-muted">Vue sur les projets et collaborateurs</small></p>
               </div>
               <div>
                    <b-button class="ml-2" :to="{ name: 'AddProjet' }" variant="success"><b-icon icon="plus"
                              scale="1.3rem" /> Créer un Projet</b-button>
               </div>
          </div>
          <b-row>
               <b-col lg="6" md="12">
                    <GroupContainer title="Mes projets">
                         <div class="d-flex justify-content-between mb-4 mx-4">
                              <Input type="text" v-model="search" placeholder="🔍 Nom du projet" size="sm"
                                   style="width:100%;" />
                         </div>
                         <div v-if="!$store.state.loading">
                              <div v-for="(projet, index) in paginatedList" :key="index" class="point py-2 d-flex"
                                   :class="index + 1 < paginatedList.length ? 'border-bottom' : ''"
                                   v-on:click="selectProject(projet, 'general')">
                                   <div class="px-4">
                                        <b-avatar :src="projet.imgsrc" icon="briefcase" size="4.5em"
                                             class="mb-3 p-1 my-auto bg-white" />
                                   </div>
                                   <div class="my-auto">
                                        <div class="text-truncate" style="font-weight: 600">{{ projet.title }}</div>
                                        <div>En cours : <b-badge pill variant="info">{{ projet.saisir + projet.venir }}
                                             </b-badge>
                                        </div>
                                        <div>Validés : <b-badge pill variant="success">{{ projet.fini }}</b-badge>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div v-else>
                              <div v-for="n in perPage" :key="n" class="point py-2 d-flex border-bottom">
                                   <div class="px-4">
                                        <b-avatar variant="info" icon="briefcase" size="4.5em"
                                             class="mb-3 p-1 my-auto" />
                                   </div>
                                   <div class="my-auto w-100">
                                        <b-skeleton :width="(Math.ceil(Math.random() * 50) + 50) + '%'"></b-skeleton>
                                        <b-skeleton :width="(Math.ceil(Math.random() * 50) + 10) + '%'"></b-skeleton>
                                        <b-skeleton :width="(Math.ceil(Math.random() * 50) + 10) + '%'"></b-skeleton>
                                   </div>
                              </div>
                         </div>
                         <b-input-group class="mt-3">
                              <b-form-group label="Pages :" label-size="sm">
                                   <b-pagination v-model="currentPage" :total-rows="filteredList.length"
                                        :per-page="perPage" :disabled="filteredList.length <= perPage" size="sm">
                                   </b-pagination>
                              </b-form-group>
                              <b-form-group label="Projets par page :" label-size="sm" class="ml-auto">
                                   <b-form-select size="sm" v-model="perPage"
                                        :options="[2, 4, 6, 8, 10, { value: $store.state.adminProjects.length, text: 'Tous' }]">
                                   </b-form-select>
                              </b-form-group>
                         </b-input-group>
                    </GroupContainer>
               </b-col>
               <b-col>
                    <GroupContainer title="Mes collaborateurs" cols="6">
                         <Input type="text" v-model="search2" placeholder="🔍 Nom du collab" size="sm"
                              class="mb-4 mx-4" />
                         <div v-for="(collab, index) in paginatedList2" :key="index" class="py-2 d-flex"
                              :class="index + 1 < paginatedList2.length ? 'border-bottom' : ''">
                              <div class="px-4">
                                   <b-avatar size="4.5em" class="mb-3 my-auto" variant="info" />
                              </div>
                              <div class="my-auto">
                                   <div class="text-truncate" style="font-weight: 600">{{
                                        collab.Prenom + ' ' +
                                             collab.Nom
                                   }}
                                   </div>
                                   <div>User : {{ collab.username }}</div>
                                   <div>Mot de passe : {{ collab.password }}</div>
                              </div>
                         </div>
                         <b-input-group class="mt-3">
                              <b-form-group label="Pages :" label-size="sm">
                                   <b-pagination v-model="currentPage2" :total-rows="filteredList2.length"
                                        :per-page="perPage2" :disabled="filteredList2.length <= perPage2" size="sm" />
                              </b-form-group>
                         </b-input-group>
                    </GroupContainer>
               </b-col>
          </b-row>
     </div>
</template>

<script>
import GroupContainer from '/src/components/GroupContainer.vue'
import Input from '/src/components/Input.vue'

export default {
     name: "AdminProjets",
     components: {
          GroupContainer,
          Input
     },
     data() {
          return {
               show: true,
               error: false,
               search: "",
               currentPage: 1,
               perPage: 6,

               search2: "",
               currentPage2: 1,
               perPage2: 6,
               selected: {}
          };
     },
     beforeMount() {
          if (!this.$store.state.collabs || !this.$store.state.collabs.length) {
               this.$store.dispatch("refreshCollabs")
          }
          this.refresh();
     },
     methods: {
          selectProject(projet, page) {
               this.$store.commit("setSelectedAdminProjectID", projet.ID);
               this.$router.push("/adminProjets/" + projet.ID + "/" + page);
          },
          refresh() {
               this.$store.dispatch("refreshProjectsAdmin");
          }
     },
     computed: {
          filteredList() {
               return this.$store.state.adminProjects.filter((projet) => {
                    return projet.title && projet.title.toLowerCase().includes(this.search.toLowerCase())
               });
          },
          filteredList2() {
               return this.$store.state.collabs.filter((collab) => {
                    return (collab.Prenom && collab.Prenom.toLowerCase().includes(this.search2.toLowerCase())) ||
                         (collab.Nom && collab.Nom.toLowerCase().includes(this.search2.toLowerCase()))
               });
          },
          paginatedList() {
               let current = (this.currentPage - 1) * this.perPage
               return this.filteredList.slice(current, current + this.perPage)
          },
          paginatedList2() {
               let current = (this.currentPage2 - 1) * this.perPage2
               return this.filteredList2.slice(current, current + this.perPage2)
          }
     },
     watch: {
          search() {
               this.currentPage = 1
          }
     }
};
</script>

<style scoped>
.point {
     cursor: pointer;
     transition: background-color 0.5s;
}

.point:hover {
     background-color: rgba(0, 100, 255, 0.1)
}

.card {
     transition: 0.15s all ease-in-out;
     transform: perspective(100px) translateZ(0px);
     border: none;
}

.card:hover {
     transform: perspective(100px) translateZ(5px);
     cursor: pointer;
     backface-visibility: hidden;
     -webkit-font-smoothing: subpixel-antialiased;
     box-shadow: rgba(0, 0, 0, 0.117647) 0 0.17rem 1rem,
          rgba(0, 0, 0, 0.117647) 0 0.17rem 0.7rem;
}

.pointer {
     cursor: pointer;
}

.yello {
     background-color: rgb(102, 177, 235);
}

.card-header {
     background-color: #055f83;
     color: white;
     text-align: center;
     font-size: 1.08rem;
}

.b-avatar {
     box-shadow: rgba(0, 0, 0, 0.2) 0 0.1rem 0.6rem,
          rgba(0, 0, 0, 0.2) 0 0.1rem 0.4rem;
}

.slide-fade-enter-active {
     transition: all 0.25s ease-out;
}

.slide-fade-leave-active {
     height: 0;
     opacity: 0;
}

.slide-fade-enter,
.slide-fade-leave-to {
     transform: translateY(-40px);
     opacity: 0;
}

#number {
     font: 400 25px "Consolas";
}
</style>