<template>
    <div v-if="$store.state.selectedAdminProject">
        <GroupContainer title="Progression moyenne des collaborateurs">
            <b-progress height="1.75rem">
                <b-progress-bar striped :value="progress" :label="`${progress}%`"></b-progress-bar>
            </b-progress>
        </GroupContainer>
        <b-row class="mt-4">
            <b-col lg="4">
              <CollaborateursProg 
                :collabs="$store.state.selectedAdminProject.collabs" 
                :selectCollab="selectCollab" 
                :selected="selected"
              />
            </b-col>
            <b-col lg="8">
                <GroupContainer title="Progression">
                  <ProgressBar :label="selected.Prenom + ' ' + selected.Nom" :value="selected.progress"/>
                  <Detail class="mt-3"/>
                </GroupContainer>
            </b-col>
        </b-row>
    </div>
  </template>
  <script>  
    import GroupContainer from '/src/components/GroupContainer.vue'
    
    import Detail from '/src/components/ProjetManager/Detail.vue'
    import CollaborateursProg from '/src/components/ProjetManager/Progression/CollaborateursProg.vue'
    import ProgressBar from '/src/components/ProgressBar.vue'
    
    export default {
    name: "Progression",
    components: {
      GroupContainer,
      Detail,
      CollaborateursProg,
      ProgressBar
    },
    data() {
      return {
        selected : null
      };
    },
    created(){
        this.$store.commit("setSelectedCollabID", this.$store.state.selectedAdminProject.collabs[0].ID);
        this.selected = this.$store.state.selectedAdminProject.collabs[0]
    },
    methods: {
      statutColor(collab) {
        if (collab.statut == "A saisir") return "warning";
        else if (collab.statut == "A venir") return "primary";
        return "success";
      },
      progressColor(value) {
        if (value <= 50) return "danger";
        else if (value <= 60) return "warning";
        else if (value <= 70) return "info";
        else if (value <= 80) return "primary";
        return "success";
      },
      selectCollab(collab){
        this.selected = collab
        this.$store.commit("setSelectedCollabID", collab.ID);
        this.$store.dispatch("refreshSelectCollabSession");
      }
    },
    computed: {
        progress(){
        const collabs = this.$store.state.selectedAdminProject.collabs
        return (collabs.reduce((sum, current) => sum + parseInt(current.progress), 0) / collabs.length).toFixed(0)
      },
    },
  };
  </script>
  
  <style scoped>

  </style>