<template>
  <router-view v-slot="{ Component }" appear style="max-width: 1000px; margin: auto">
    <transition name="fade" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>
<script>
export default {
  name: "ProjetsRouter",
};
</script>

<style scoped>
.fade-enter-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>