<template>
  <GroupContainer title="Formulaire">
    <div>
      <div>
        <b-button-toolbar>
          <b-button-group size="sm" class="mr-3">
            <b-button variant="primary" @click="showAll"
              ><b-icon icon="plus-circle" /> Show all</b-button
            >
            <b-button variant="info" @click="hideAll"
              ><b-icon icon="dash-circle" /> Hide all</b-button
            >
            <b-button variant="success" @click="saveChanges" class="ml-4"
              ><b-icon icon="clipboard-check" /> Enregistrer</b-button
            >
          </b-button-group>
        </b-button-toolbar>
        <div class="mt-3 pb-3">
          <b-container class="mb-3 py-2 categories">
            <b-row>
              <b-col cols="1" class="text-center">
                <strong>#</strong>
              </b-col>
              <b-col cols="7">
                <strong>Catégorie</strong>
              </b-col>
              <b-col cols="1">
                <strong>Coef</strong>
              </b-col>
              <b-col cols="3">
                <b-button
                  class="float-right"
                  variant="success"
                  size="sm"
                  @click="showCat = true"
                  ><b-icon icon="plus-circle" /> Ajouter</b-button
                >
              </b-col>
            </b-row>
          </b-container>
          <ul class="custom-list">
            <li
              v-for="(cat, i) in $store.state.selectedAdminProject.forumulaire"
              :key="i"
              class="mt-1"
            >
              <b-container>
                <b-row>
                  <b-col cols="1">
                    <div class="text-center categories-font py-2">
                      {{ cat.pos }}
                    </div>
                  </b-col>
                  <b-col cols="7">
                    <b-input
                      type="text"
                      v-model="cat.nom"
                      class="custom-input categories-font"
                    />
                  </b-col>
                  <b-col cols="1">
                    <b-input
                      type="number"
                      v-model="cat.coef"
                      class="custom-input categories-font text-center"
                    />
                  </b-col>
                  <b-col cols="3">
                    <b-button-group size="sm">
                      <b-button
                        title="Monter"
                        @click="
                          up($store.state.selectedAdminProject.forumulaire, i)
                        "
                        :variant="i == 0 ? 'secondary' : 'info'"
                      >
                        <b-icon icon="arrow-up" />
                      </b-button>
                      <b-button
                        title="Descendre"
                        @click="
                          down($store.state.selectedAdminProject.forumulaire, i)
                        "
                        :variant="
                          i ==
                          $store.state.selectedAdminProject.forumulaire.length -
                            1
                            ? 'secondary'
                            : 'info'
                        "
                      >
                        <b-icon icon="arrow-down" />
                      </b-button>
                    </b-button-group>
                    <b-button-group size="sm" class="ml-2">
                      <b-button
                        variant="danger"
                        @click="removeCategorie(cat)"
                        title="Supprimer"
                      >
                        <b-icon icon="trash" />
                      </b-button>
                    </b-button-group>
                    <b-button
                      title="Dérouler"
                      @click="cat.toggle = !cat.toggle"
                      size="sm"
                      pill
                      :variant="cat.toggle ? 'outline-info' : 'outline-primary'"
                      class="float-right"
                      ><b-icon
                        :icon="
                          cat.toggle ? 'caret-down-fill' : 'caret-left-fill'
                        "
                    /></b-button>
                  </b-col>
                </b-row>
              </b-container>
              <transition name="slide">
                <div v-if="cat.toggle" class="mx-4 my-3 pb-3">
                  <b-container class="mb-3 py-2 sous-categories">
                    <b-row>
                      <b-col cols="1" class="text-center">
                        <strong>#</strong>
                      </b-col>
                      <b-col cols="7">
                        <strong>Sous catégorie</strong>
                      </b-col>
                      <b-col cols="1">
                        <strong>Coef</strong>
                      </b-col>
                      <b-col cols="3">
                        <b-button
                          class="float-right"
                          variant="success"
                          size="sm"
                          @click="
                            selectedCat = cat.SousCategories;
                            showScat = true;
                          "
                        >
                          <b-icon icon="plus-circle" /> Ajouter</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-container>
                  <ul class="custom-list">
                    <li
                      v-for="(scat, y) in cat.SousCategories"
                      :key="y"
                      class="mt-1"
                    >
                      <b-container>
                        <b-row>
                          <b-col cols="1">
                            <div class="text-center sous-categories-font py-2">
                              {{ cat.pos }}.{{ scat.pos }}
                            </div>
                          </b-col>
                          <b-col cols="7">
                            <b-input
                              type="text"
                              v-model="scat.nom"
                              class="custom-input sous-categories-font"
                            />
                          </b-col>
                          <b-col cols="1">
                            <b-input
                              type="number"
                              v-model="scat.coef"
                              class="
                                custom-input
                                sous-categories-font
                                text-center
                              "
                            />
                          </b-col>
                          <b-col cols="3">
                            <b-button-group size="sm">
                              <b-button
                                @click="up(cat.SousCategories, y)"
                                :variant="y == 0 ? 'secondary' : 'info'"
                              >
                                <b-icon icon="arrow-up" />
                              </b-button>
                              <b-button
                                @click="down(cat.SousCategories, y)"
                                :variant="
                                  y == cat.SousCategories.length - 1
                                    ? 'secondary'
                                    : 'info'
                                "
                              >
                                <b-icon icon="arrow-down" />
                              </b-button>
                              <b-button title="Déplacer" variant="primary" @click="showTMP = true">
                                <b-icon icon="arrow-return-right" />
                              </b-button>
                            </b-button-group>
                            <b-button-group size="sm">
                              <b-button
                                variant="danger"
                                class="ml-2"
                                @click="
                                  removeSubCategorie(cat.SousCategories, scat)
                                "
                              >
                                <b-icon icon="trash" />
                              </b-button>
                            </b-button-group>
                            <b-button
                              @click="scat.toggle = !scat.toggle"
                              size="sm"
                              pill
                              class="float-right"
                              :variant="
                                scat.toggle ? 'outline-info' : 'outline-primary'
                              "
                              ><b-icon
                                :icon="
                                  scat.toggle
                                    ? 'caret-down-fill'
                                    : 'caret-left-fill'
                                "
                            /></b-button>
                          </b-col>
                        </b-row>
                      </b-container>
                      <transition name="slide">
                        <div v-if="scat.toggle" class="mx-4 my-3">
                          <b-container class="py-2 aptitudes">
                            <b-row>
                              <b-col cols="1" class="text-center">
                                <strong>#</strong>
                              </b-col>
                              <b-col cols="7">
                                <strong>Aptitude</strong>
                              </b-col>
                              <b-col cols="1">
                                <strong>Coef</strong>
                              </b-col>
                              <b-col cols="3">
                                <b-button
                                  class="float-right"
                                  variant="success"
                                  size="sm"
                                  @click="
                                    showApt = true;
                                    selectedScat = scat.aptitudes;
                                    tmpApt = {
                                      nom: '',
                                      description: '',
                                      type: '',
                                    };
                                  "
                                  ><b-icon icon="plus-circle" />
                                  Ajouter</b-button
                                >
                              </b-col>
                            </b-row>
                          </b-container>
                          <ul class="custom-list">
                            <li
                              v-for="(apt, z) in scat.aptitudes"
                              :key="z"
                              class="top py-2"
                            >
                              <div>
                                <b-container>
                                  <b-row>
                                    <b-col cols="1">
                                      <div class="aptitudes-font py-2">
                                        {{ cat.pos }}.{{ scat.pos }}.{{
                                          apt.pos
                                        }}
                                      </div>
                                    </b-col>
                                    <b-col cols="7">
                                      <b-row>
                                        <b-col cols="12">
                                          <strong>[{{ apt.type[0] }}]</strong>
                                          <span class="aptitudes-font">{{
                                            apt.nom
                                          }}</span>
                                        </b-col>
                                      </b-row>
                                      <b-row class="mt-1">
                                        <b-col cols="12">
                                          <span
                                            v-if="apt.description"
                                            class="aptitudes-des-font"
                                            >{{ apt.description }}</span
                                          >
                                          <span
                                            v-else
                                            class="aptitudes-des-font"
                                            style="
                                              color: red;
                                              font-weight: bold;
                                            "
                                            >Pas de descriprion</span
                                          >
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                    <b-col cols="1">
                                      <b-input
                                        type="number"
                                        v-model="apt.coef"
                                        class="
                                          custom-input
                                          aptitudes-font
                                          text-center
                                        "
                                      />
                                    </b-col>
                                    <b-col cols="3">
                                      <b-button-group size="sm">
                                        <b-button
                                          @click="up(scat.aptitudes, z)"
                                          :variant="
                                            z == 0 ? 'secondary' : 'info'
                                          "
                                        >
                                          <b-icon icon="arrow-up" />
                                        </b-button>
                                        <b-button
                                          @click="down(scat.aptitudes, z)"
                                          :variant="
                                            z == scat.aptitudes.length - 1
                                              ? 'secondary'
                                              : 'info'
                                          "
                                        >
                                          <b-icon icon="arrow-down" />
                                        </b-button>
                                      </b-button-group>
                                      <b-button-group size="sm" class="ml-2">
                                        <b-button
                                          variant="danger"
                                          @click="
                                            removeAptitude(scat.aptitudes, apt)
                                          "
                                        >
                                          <b-icon icon="trash" />
                                        </b-button>
                                        <b-button
                                          variant="primary"
                                          @click="
                                            tmpApt = apt;
                                            tmpScat = scat.nom;
                                            showApt = true;
                                          "
                                        >
                                          <b-icon icon="pencil" />
                                        </b-button>
                                      </b-button-group>
                                    </b-col>
                                  </b-row>
                                </b-container>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </transition>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>
          </ul>
        </div>
      </div>
      <b-modal
        v-model="showCat"
        centered
        hide-footer
        title="Ajouter une catégorie"
        :header-bg-variant="'black'"
      >
        <b-form @submit="addCategorie">
          <b-input
            v-model="tmpCat"
            placeholder="Nom de la catégorie"
            required
          />
          <div class="mt-4 float-right">
            <b-button
              size="sm"
              variant="danger"
              class="mr-2"
              @click="
                showCat = false;
                tmpCat = '';
              "
            >
              Annuler
            </b-button>
            <b-button type="submit" size="sm" variant="success">
              Ajouter
            </b-button>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        v-model="showScat"
        centered
        hide-footer
        title="Ajouter une sous-catégorie"
        :header-bg-variant="'black'"
      >
        <b-form @submit="addSubCategorie">
          <b-input
            v-model="tmpScat"
            placeholder="Nom de la sous-catégorie"
            required
          />
          <div class="mt-4 float-right">
            <b-button
              size="sm"
              variant="danger"
              class="mr-2"
              @click="
                showScat = false;
                tmpCat = '';
              "
            >
              Annuler
            </b-button>
            <b-button type="submit" size="sm" variant="success">
              Ajouter
            </b-button>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        v-model="showApt"
        centered
        size="lg"
        hide-footer
        :title="tmpApt.pos ? 'Modifier une aptitude' : 'Ajouter une aptitude'"
        :header-bg-variant="'black'"
      >
        <b-form @submit="addAptitude" class="px-4">
          <b-row>
            <b-col cols="9">
              <b-form-group
                id="fieldset-1"
                description="Max 70 caracatères"
                label="Titre de l'apitude"
              >
                <b-form-input v-model="tmpApt.nom" trim></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                id="fieldset-1"
                description="Pratique ou Théorique"
                label="Type de l'apitude"
              >
                <b-form-select
                  :options="options"
                  v-model="tmpApt.type"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            id="fieldset-2"
            description="Max 150 caracatères"
            label="Description de l'apitude"
          >
            <b-form-textarea
              v-model="tmpApt.description"
              placeholder="Ex: ...."
              required
              trim
              rows="4"
            />
          </b-form-group>
          <div class="mt-4 float-right">
            <b-button
              size="sm"
              variant="danger"
              class="mr-2"
              @click="showApt = false"
            >
              Annuler
            </b-button>
            <b-button type="submit" size="sm" variant="success">
              {{ tmpApt.pos ? "Enregistrer" : "Ajouter" }}
            </b-button>
          </div>
        </b-form>
      </b-modal>
      <b-modal
        v-model="showTMP"
        centered
        size="lg"
        hide-footer
        title="Déplacer une sous-catégorie"
        :header-bg-variant="'black'"
      >
      <b-form @submit="switchCat">
        <b-form-group
          label="Catégorie de destination :"
        label-for="input-1"
        description="Séléctionner la nouvelle Catégorie parente"
        >
        <b-form-select>
          <b-form-select-option  
            v-for="(cat, i) in $store.state.selectedAdminProject.forumulaire"
            :key="i"
          >{{cat.nom}}</b-form-select-option>
        </b-form-select>
        </b-form-group>
        <b-button type="submit" size="sm" variant="success" class="float-right">
          Enregistrer
        </b-button>
      </b-form>
      </b-modal>
    </div>
  </GroupContainer>
</template>
<script>  import GroupContainer from '/src/components/GroupContainer.vue'

export default {
  name: "Formulaire",
  components: {
    GroupContainer
  },
  data() {
    return {
      edited : false,
      show2: false,
      search3: "",
      error: false,
      currentPage3: 1,
      perPage3: 5,
      memail: "",
      options: [
        {
          value: "Pratique",
          text: "Pratique",
        },
        {
          value: "Théorique",
          text: "Théorique",
        },
      ],
      tmpCat: "",
      tmpScat: "",
      tmpApt: {
        nom: "",
        description: "",
        type: "",
      },
      showCat: false,
      showScat: false,
      showApt: false,
      selectedCat: null,
      selectedScat: null,
      showTMP : false
    };
  },
  methods: {
    switchCat(e){
      e.preventDefault();
      this.showTMP = false
    },
    statutIcon(collab) {
      return collab;
    },
    addCategorie(e) {
      this.edited = true;
      e.preventDefault();
      this.$store.state.selectedAdminProject.forumulaire.push({
        nom: this.tmpCat,
        pos: this.$store.state.selectedAdminProject.forumulaire.length + 1,
        SousCategories: [],
        toggle: false,
        coef: 1
      });
      this.tmpCat = "";
      this.showCat = false;
    },
    removeCategorie(cat) {
      this.edited = true;
      let cats = this.$store.state.selectedAdminProject.forumulaire;
      if (cat.ID) this.$store.getters.deleted.cats.push(cat);
      let index = cats.indexOf(cat);
      cats.splice(index, 1);
      for (let i = index; i < cats.length; i++) {
        cats[i].pos--;
      }
    },
    addSubCategorie(e) {
      this.edited = true;
      e.preventDefault();
      this.selectedCat.push({
        nom: this.tmpScat,
        pos: this.selectedCat.length + 1,
        aptitudes: [],
        toggle: false,
        coef: 1
      });
      this.tmpScat = "";
      this.showScat = false;
    },
    removeSubCategorie(cat, scat) {
      this.edited = true;
      let index = cat.indexOf(scat);
      if (scat.ID) this.$store.getters.deleted.scats.push(scat);
      cat.splice(index, 1);
      for (let i = index; i < cat.length; i++) {
        cat[i].pos--;
      }
    },
    addAptitude(e) {
      e.preventDefault();
      this.edited = true;
      if (!this.tmpApt.pos) {
        this.selectedScat.push({
          nom: this.tmpApt.nom,
          type: this.tmpApt.type,
          description: this.tmpApt.description,
          pos: this.selectedScat.length + 1,
          coef: 1
        });
      }
      this.showApt = false;
    },
    removeAptitude(csat, apt) {
      this.edited = true;
      let index = csat.indexOf(apt);
      if (apt.ID) this.$store.getters.deleted.apts.push(apt);
      csat.splice(index, 1);
      for (let i = index; i < csat.length; i++) {
        csat[i].pos--;
      }
    },
    showAll() {
      for (let cat of this.$store.state.selectedAdminProject.forumulaire) {
        for (let scat of cat.SousCategories) scat.toggle = true;
        cat.toggle = true;
      }
    },
    hideAll() {
      for (let cat of this.$store.state.selectedAdminProject.forumulaire) {
        for (let scat of cat.SousCategories) scat.toggle = false;
        cat.toggle = false;
      }
    },
    sort_array(ar) {
      ar.sort(function (a, b) {
        return a.pos - b.pos;
      });
    },
    up(ar, index) {
      if (index) {
        ar[index].pos--;
        ar[index - 1].pos++;
        this.sort_array(ar);
      }
    },
    down(ar, index) {
      if (index != ar.length - 1) {
        ar[index].pos++;
        ar[index + 1].pos--;
        this.sort_array(ar);
      }
    },
    leave(event){
      if(!this.edited) return
      if(event){
        event.preventDefault()
        event.returnValue = ""
      }
      return confirm("Vous allez quitter la page sans enregistrer les modficiations")
    },
    saveChanges() {
      this.$store.dispatch("saveAdminProjectChanges")
      this.edited = false
    }
  },
  created(){
    window.addEventListener('beforeunload', this.leave)
  },
  beforeRouteLeave(to, from, next){
    next(this.leave())
  }
};
</script>

<style scoped>
.custom-list {
  list-style-type: none;
  padding: 0;
}

.custom-list li {
  margin: 0;
  padding: 0;
}

.custom-input {
  padding: 0 10px;
  background-color: white;
}

.categories,
.sous-categories,
.aptitudes {
  height: 48px;
  color: white;
}

.categories {
  background-color: #544e61;
}

.sous-categories {
  background-color: #6e8894;
}

.aptitudes {
  background-color: #85baa1;
}

.categories-font {
  font-size: 16px;
  font-weight: bold;
}

.sous-categories-font {
  font-size: 15px;
  font-weight: bold;
}

.aptitudes-font {
  font-size: 14px;
}

.aptitudes-des-font {
  font-size: 13px;
  font-style: italic;
}

.custom-input:focus {
  box-shadow: none;
}

.custom-input:focus,
.custom-input-edit:focus {
  outline: none;
}

.b-avatar {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 6px, rgba(0, 0, 0, 0.2) 0px 1px 4px;
}

ul li {
  margin-left: 10px;
}

.slide-enter-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}

.slide-leave-active {
  -moz-transition-duration: 0.15s;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
}

.slide-enter-to,
.slide-leave {
  max-height: 10rem;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>