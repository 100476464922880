<template>
   <GroupContainer title="Collaborateurs" :badge="collabs.length">
      <div v-if="collabs.length">
         <Input v-model="search" class="mb-3" size="sm" placeholder="🔍 Collabs sur le projet"/>
         <b-row v-for="collab in paginationList" :key="collab.ID" class="pointer my-2"
            @click="selectCollab(collab)">
            <b-col>
               <ProgressBar :value="collab.progress" :label="`${collab.Prenom[0] + '. ' + collab.Nom}`" />
            </b-col>
            <b-col cols="2" v-if="collab.ID == selected.ID" ><b-icon icon="arrow-right"/></b-col>
         </b-row>
      </div>
      <b-row class="mt-4">
            <b-pagination class="mt-auto mr-5" size="sm" v-model="currentPage" :total-rows="collabs.length"
                :per-page="perPage" />
        </b-row>
   </GroupContainer>
</template>
  
  <script>
  import GroupContainer from '/src/components/GroupContainer.vue'
  import ProgressBar from '/src/components/ProgressBar.vue'
  import Input from '/src/components/Input.vue'
  
  export default {
   name: "CollaborateursProg",
   components : {
      GroupContainer,
      ProgressBar,
      Input
   },
    props : [
      "collabs",
      "selectCollab",
      "selected"
    ],
    data(){
      return {
         currentPage : 1,
         perPage : 10,
         search : ""
      }
    },
    computed:{
      paginationList() {
         return this.filteredList.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
         );
      },
      filteredList() {
         return this.collabs.filter((collab) => {
            return (
               (collab.Nom.toLowerCase().includes(this.search.toLowerCase()) ||
               collab.Prenom.toLowerCase().includes(this.search.toLowerCase()))
            );
         })
      },
    }
  }
  </script>
  
  <style scoped>

@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";


    .pointer {
      cursor:pointer
    }
  </style>
  