<template>
    <GroupContainer title="Collaborateurs" :badge="collabsProjet.length">
        <!-- MODALS -->
        <Modal v-model="showAdd" title="Ajouter un collaborateur">
            <div>
                <Input placeholder="🔍 Nom ou Prénom du collaborateur" type="text" v-model="search2"></Input>
                <div class="my-5 px-2">
                    <div v-for="(collab, index) in paginationList2" :key="collab.ID"
                         :class="index < paginationList2.length - 1 ? 'border-bottom' : ''">
                         <b-row class="py-2">
                              <b-col cols="6" class="text-truncate my-auto">
                              <b-avatar variant="primary" class="mr-2">{{collab.Prenom[0]+collab.Nom[0]}}
                              </b-avatar> {{collab.Prenom + ' ' + collab.Nom}}
                              </b-col>
                              <b-col  class="my-auto">
                                   <b-button variant="outline-success" class="float-right" @click="addCollab(collab)">Ajouter</b-button>
                              </b-col>
                         </b-row>
                    </div>
               </div>
                <b-pagination v-model="currentPage2" :total-rows="filteredList2.length" :per-page="perPage2"
                    aria-controls="itemList" size="sm">
               </b-pagination>
            </div>
            <template v-slot:buttons>
                <b-button @click="showAdd=false" variant="outline-danger">Annuler</b-button>
            </template>
        </Modal>

        <Modal  v-model="showDelete" title="Supprimer le collab">
            <p>Êtes-vous sur de vouloir supprimer le collaborateur</p><p class="text-center"><strong>{{collab.Prenom + ' ' + collab.Nom}}</strong></p><p> du projet ?</p>
            <template v-slot:buttons>
                <b-button @click="showDelete = false" variant="outline-danger" class="mr-2">
                    Annuler
                </b-button>
                <b-button @click="removeCollab()" variant="outline-primary">
                    <b-icon icon="trash" /> Supprimer
                </b-button>
            </template>
        </Modal>

        <!-- MODALS -->

        <!-- TABLE / List -->
          <div class="d-flex">
               <Input  type="text" v-model="search" placeholder="🔍 Nom, Prénom du collab" size="sm" style="width:100%; max-width:500px;"/>
               <b-button 
                    @click="showAddModal"
                    variant="light"
                    class="ml-2"
                    v-b-tooltip.hover 
                    title="Ajouter un collaborateur"
               >
                    <b-icon icon="person-plus-fill" scale="1.25rem"/>
               </b-button>
          </div>
        <b-row class="border-bottom list-head mt-3">
            <b-col cols="6">
                USER
            </b-col>
            <!--b-col cols="3">
                STATUT
            </b-col-->
            <b-col cols="4">
                ROLE
            </b-col>
            <b-col cols="2">
                ACTIONS
            </b-col>
        </b-row>
        <div v-if="paginationList.length">
            <div v-for="(collab, index) in paginationList" :key="collab.ID"
                :class="index < paginationList.length - 1 ? 'border-bottom' : ''">
                <b-row class="py-2">
                    <b-col cols="6" class="text-truncate my-auto">
                        <b-avatar variant="primary" class="mr-2">{{collab.Prenom[0]+collab.Nom[0]}}
                        </b-avatar> {{collab.Prenom + ' ' + collab.Nom}}
                    </b-col>
                    <b-col cols="4" class="my-auto">
                        Collaborateur
                    </b-col>
                    <b-col cols="2" class="my-auto">
                        <b-button variant="link" @click="showDeleteModal(collab)" v-b-tooltip.hover 
                    title="Supprimer le collaborateur">
                            <b-icon icon="trash-fill" variant="danger" scale="1.2"/>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div v-else>
            <div id="number">
                <h1><strong>Ooops!</strong></h1>
                <p>Aucun résultat</p>
            </div>
        </div>
        <!-- TABLE / LIST -->

        <!-- PAGINATION -->
        <b-row class="mt-3">
            <b-pagination class="mt-auto mr-5" size="sm" v-model="currentPage" :total-rows="filteredList.length"
                :per-page="perPage" />
        </b-row>
        <!-- PAGINATION -->
    </GroupContainer>
</template>
  
  <script>
    import GroupContainer from '/src/components/GroupContainer.vue'
    import Modal from '/src/components/Modal.vue'
    import Input from '/src/components/Input.vue'

    export default {
        name: "Collaborateurs",
        components : {
            GroupContainer,
            Modal,
            Input
        },
        props : [
            "collabsProjet",
            "collabsAll"
        ],
        data(){
            return {
                search : "",
                search2 : "",

                showAdd : false,

                showDelete : false,
                collab: {},

                currentPage : 1,
                perPage : 7,

                currentPage2 : 1,
                perPage2 : 5
            }
        },
        methods : {
            showAddModal(){
                this.showAdd = true
            },
            statutColor(collab) {
                if (collab.statut == "A saisir") return "warning";
                else if (collab.statut == "A venir") return "info";
                return "success";
            },
            progressColor(value) {
                if (value <= 50) return "danger";
                else if (value <= 60) return "warning";
                else if (value <= 70) return "info";
                else if (value <= 80) return "primary";
                return "success";
            },
            showDeleteModal(collab){
                this.showDelete = true
                this.collab = collab
            },
            removeCollab() {
                this.axios
                .get(
                    "/removeCollab.php?ID=" +
                    this.collab.ID +
                    "&IDprojet=" +
                    this.$store.getters.selectedAdminProject.ID
                )
                .then(async (response) => {
                    if (response.data != "ko") {
                    this.$store.dispatch("refreshSelectedAdminProject");
                    this.showDelete = false;
                    }
                });
            },
            addCollab(collab) {
                let data = new FormData();
                data.set("collab", JSON.stringify(collab));
                console.log(data)
                this.axios
                .post(
                "/addCollab.php?ID=" + this.$store.getters.selectedAdminProject.ID,
                data
                )
                .then((response) => {
                if (response.data != "ko") {
                    this.$store.dispatch("refreshSelectedAdminProject");
                    this.search2 = ""
                    this.showAdd = false;
                }
                });
            }
        },
        computed : {
            paginationList() {
                return this.filteredList.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
                );
            },
            filteredList() {
                return this.collabsProjet.filter((collab) => {
                    return (
                        (collab.Nom.toLowerCase().includes(this.search.toLowerCase()) ||
                        collab.Prenom.toLowerCase().includes(this.search.toLowerCase()))
                    );
                })
            },
            paginationList2() {
                return this.filteredList2.slice(
                    (this.currentPage2 - 1) * this.perPage2,
                    this.currentPage2 * this.perPage2
                );
            },
            filteredList2() {
                return this.collabsAll.filter((collab) => {
                    return (
                        collab.Nom.toLowerCase().includes(this.search2.toLowerCase()) ||
                        collab.Prenom.toLowerCase().includes(this.search2.toLowerCase())
                    );
                });
            },
        }
    }
  </script>

  <style scoped>
    .list-head {
          color: rgb(161, 165, 183);
          font-weight : 600;
          font-size: 14px;
          padding: 1px
      }
      #number {
        font: 400 25px "Consolas";
        text-align: center;
        margin : 15px
    }
  </style>