<template>
  <div>
    <b-row class="mt-2">
      <b-col lg="3" md="12" class="py-3 mx-auto text-center">
        <b-avatar
          variant="info"
          icon="briefcase"
          size="12rem"
          :src="$store.state.selectedProject.imgsrc"
          rounded
        />
      </b-col>
      <b-col class="py-2">
        <h3 class="text-center">{{ $store.state.selectedProject.nom }}</h3>
        <div class="mt-4">
          <span
            >Statut :
            <strong>{{ $store.state.selectedProject.statut }}</strong></span
          ><br />
          <span v-if="this.$store.state.selectedProject.dateSaisie">
            <span v-if="daysBeforeSaisie > 0">
              A saisir : dans
              <span style="color: #6495ed; font-weight: bold">
                {{ daysBeforeSaisie }}</span
              >
              jour(s)
            </span>
            <span v-if="daysBeforeSaisie == 0">
              A saisir :
              <span style="color: green; font-weight: bold"> Aujourd'hui</span>
            </span>
            <span v-if="daysBeforeSaisie < 0">
              A saisir :
              <span style="color: red; font-weight: bold">
                {{ -daysBeforeSaisie }}</span
              >
              jour(s) de retard
            </span>
          </span>
          <br />
          <span>Progression globale: </span>
          <b-progress height="1.2rem" class="mt-3">
            <b-progress-bar
              striped
              :value="$store.state.selectedProject.progression"
              :label="`${$store.state.selectedProject.progression}%`"
              :variant="progressColor($store.state.selectedProject.progression)"
            ></b-progress-bar>
          </b-progress>
        </div>
        <div
          class="text-center pt-4"
          v-if="$store.state.selectedProject.statut == 'A saisir'"
        >
          <b-button
            class="mx-auto"
            :to="'/projets/' + $route.params.id + '/saisie'"
            variant="primary"
            ><b-icon icon="pencil" /> Saisir votre progression</b-button
          >
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="pt-4 px-lg-5">
        <div v-if="$store.state.selectedProject">
          <b-button-toolbar>
            <b-button-group size="sm" class="mr-3">
              <b-button variant="primary" @click="showAll"
                ><b-icon icon="plus-circle" /> Show all</b-button
              >
              <b-button variant="info" @click="hideAll"
                ><b-icon icon="dash-circle" /> Hide all</b-button
              >
            </b-button-group>
          </b-button-toolbar>
          <hr />
          <div>
            <ul class="custom-list">
              <li
                v-for="(cat, index) in $store.state.selectedProject.categories"
                :key="index"
                class="p-0 py-2"
                :class="
                  index != $store.state.selectedProject.categories - 1
                    ? 'border-bottom'
                    : ''
                "
              >
                <b-container>
                  <b-row v-on:click="cat.toggle = !cat.toggle">
                    <b-col md="7" cols="10">
                      <strong>{{ cat.pos }}</strong>
                      <span
                        >: {{ cat.nom }}
                        <small>(coef : {{ cat.coef }})</small></span
                      >
                    </b-col>
                    <b-col md="3" cols="0" class="d-none d-md-inline">
                      <b-progress height="25px" class="mt-2">
                        <b-progress-bar
                          striped
                          :value="cat.progress"
                          :label="`${cat.progress}%`"
                          :variant="progressColor(cat.progress)"
                        ></b-progress-bar>
                      </b-progress>
                    </b-col>
                    <b-col cols="2">
                      <b-icon
                        class="float-right mt-1"
                        :icon="cat.toggle ? 'chevron-down' : 'chevron-left'"
                        scale="1.2"
                        variant="info"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="d-block d-md-none">
                    <b-col cols="10" class="my-2 mx-2">
                      <b-progress height="10px">
                        <b-progress-bar
                          :value="cat.progress"
                          :variant="progressColor(cat.progress)"
                        ></b-progress-bar>
                      </b-progress>
                    </b-col>
                  </b-row>
                  <b-row v-show="cat.toggle">
                    <b-col cols="12">
                      <transition name="slide">
                        <ul
                          v-show="cat.toggle"
                          class="mt-2 ml-md-4 custom-list"
                        >
                          <li
                            v-for="(scat, index) in cat.sousCategories"
                            :key="index"
                            class="p-0 py-2 border-top"
                          >
                            <b-container>
                              <b-row v-on:click="scat.toggle = !scat.toggle">
                                <b-col md="7" cols="10">
                                  <strong>{{ cat.pos }}.{{ scat.pos }}</strong>
                                  :
                                  <span
                                    >{{ scat.nom }}
                                    <small
                                      >(coef : {{ scat.coef }})</small
                                    ></span
                                  >
                                </b-col>
                                <b-col
                                  md="3"
                                  cols="0"
                                  class="d-none d-md-inline"
                                >
                                  <b-progress height="25px">
                                    <b-progress-bar
                                      striped
                                      :value="scat.progress"
                                      :label="`${scat.progress}%`"
                                      :variant="progressColor(scat.progress)"
                                    ></b-progress-bar>
                                  </b-progress>
                                </b-col>
                                <b-col cols="2">
                                  <b-icon
                                    class="float-right mt-1"
                                    :icon="
                                      scat.toggle
                                        ? 'chevron-down'
                                        : 'chevron-left'
                                    "
                                    scale="1.2"
                                    variant="info"
                                  />
                                </b-col>
                              </b-row>
                              <b-row class="d-block d-md-none">
                                <b-col cols="10" class="my-2 mx-2">
                                  <b-progress height="10px">
                                    <b-progress-bar
                                      :value="scat.progress"
                                      :variant="progressColor(scat.progress)"
                                    ></b-progress-bar>
                                  </b-progress>
                                </b-col>
                              </b-row>
                              <b-row v-show="scat.toggle">
                                <b-col cols="12">
                                  <transition name="slide">
                                    <ul
                                      class="mt-2 ml-md-4 custom-list"
                                      v-show="scat.toggle"
                                    >
                                      <li
                                        v-for="(apt, z) in scat.aptitudes"
                                        :key="z"
                                        class="py-2 border-top"
                                      >
                                        <b-container>
                                          <b-row>
                                            <b-col md="7" cols="10">
                                              <strong
                                                >{{ cat.pos }}.{{ scat.pos }}.{{
                                                  apt.pos
                                                }}</strong
                                              >
                                              : {{ apt.nom }}
                                              <small
                                                >(coef : {{ apt.coef }})</small
                                              ></b-col
                                            ><b-col
                                              md="3"
                                              cols="0"
                                              class="d-none d-md-inline"
                                            >
                                              <b-progress height="25px">
                                                <b-progress-bar
                                                  striped
                                                  :value="(apt.prev / 5) * 100"
                                                  :label="`${
                                                    (apt.prev / 5) * 100
                                                  }%`"
                                                  :variant="
                                                    progressColor(
                                                      (apt.prev / 5) * 100
                                                    )
                                                  "
                                                ></b-progress-bar>
                                              </b-progress>
                                            </b-col>
                                            <b-col cols="2">
                                              <b-button
                                                class="float-right"
                                                size="sm"
                                                variant="outline-primary"
                                                @click="viewApt(apt)"
                                                pill
                                                ><b-icon icon="eye"
                                              /></b-button>
                                            </b-col>
                                          </b-row>
                                          <b-row class="d-block d-md-none">
                                            <b-col cols="10" class="my-2 mx-2">
                                              <b-progress height="10px">
                                                <b-progress-bar
                                                  :value="scat.progress"
                                                  :variant="
                                                    progressColor(scat.progress)
                                                  "
                                                ></b-progress-bar>
                                              </b-progress>
                                            </b-col>
                                          </b-row>
                                        </b-container>
                                      </li>
                                    </ul>
                                  </transition>
                                </b-col>
                              </b-row>
                            </b-container>
                          </li>
                        </ul>
                      </transition>
                    </b-col>
                  </b-row>
                </b-container>
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-model="view"
      v-if="selectedApt"
      centered
      hide-backdrop
      hide-footer
      id="modal-lg"
      size="lg"
      :title="selectedApt.nom"
    >
      <div class="py-2 px-5">
        <p class="py-3">
          {{ selectedApt.description }}
        </p>
        <b-progress height="25px" class="my-3">
          <b-progress-bar
            striped
            :value="(selectedApt.prev / 5) * 100"
            :label="`${(selectedApt.prev / 5) * 100}%`"
            :variant="progressColor((selectedApt.prev / 5) * 100)"
          ></b-progress-bar>
        </b-progress>
        <b-form-group
          v-if="edit"
          label="Avancée : "
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="radio-group-1"
            v-model="selectedAptUptadte"
            :options="selectedApt.type == 'Pratique' ? optionsPra : optionsTheo"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
            required
            stacked
          ></b-form-radio-group>
        </b-form-group>
        <span v-else
          >Réponse :
          {{
            selectedApt.type == "Pratique"
              ? optionsPra[selectedApt.prev].text
              : optionsTheo[selectedApt.prev].text
          }}</span
        >
        <div class="py-5 float-right">
          <b-button
            size="sm"
            @click="
              edit = false;
              view = false;
            "
            variant="primary"
            class="mr-2"
            >Retour</b-button
          >
          <b-button
            size="sm"
            v-if="edit"
            @click="saveApt"
            variant="danger"
            class="mr-2"
            >Annuler</b-button
          >

          <b-button size="sm" v-if="edit" @click="saveApt" variant="success"
            >Mettre à jour</b-button
          >
          <b-button
            size="sm"
            v-if="!edit && selectedApt.prevID"
            variant="warning"
            @click="editApt"
            ><b-icon icon="pencil" /> Editer</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>

export default {
  name: "Projet",
  data() {
    return {
      optionsTheo: [
        { value: 0, text: "Rien fait" },
        { value: 1, text: "Lu/Expliqué une fois (en partie)" },
        { value: 2, text: "Lu/Expliqué une fois (totalité)" },
        { value: 3, text: "Compris/Connu en partie" },
        { value: 4, text: "Compris/Connu en totalité" },
        { value: 5, text: "Maîtrisé (Autonome)" },
      ],
      optionsPra: [
        { value: 0, text: "Ne sait pas encore ce que c’est" },
        { value: 1, text: "Expliqué" },
        { value: 2, text: "Vu (Double passif)" },
        { value: 3, text: "Réalisé avec accompagnement (Double Actif)" },
        { value: 4, text: "Réalisé sans aide (Double Actif)" },
        { value: 5, text: "Maîtrisé (Autonomie)" },
      ],
      edit: false,
      view: false,
      selectedApt: null,
      selectedAptUptadte: 0,
    };
  },
  methods: {
    progressColor(value) {
      if (value <= 50) return "danger";
      else if (value <= 60) return "warning";
      else if (value <= 70) return "info";
      else if (value <= 80) return "primary";
      return "success";
    },

    showAll() {
      for (let cat of this.$store.state.selectedProject.categories) {
        for (let scat of cat.sousCategories) scat.toggle = true;
        cat.toggle = true;
      }
    },
    hideAll() {
      for (let cat of this.$store.state.selectedProject.categories) {
        for (let scat of cat.sousCategories) scat.toggle = false;
        cat.toggle = false;
      }
    },
    viewApt(apt) {
      this.selectedApt = apt;
      this.view = true;
    },
    editApt() {
      this.selectedAptUptadte = this.selectedApt.prev;
      this.edit = true;
    },
    saveApt() {
      this.selectedApt.prev = this.selectedAptUptadte;
      this.edit = false;
      let data = new FormData();
      data.set(
        "back",
        JSON.stringify({
          ID: this.selectedApt.prevID,
          response: this.selectedApt.prev,
        })
      );
      this.axios.post("/update.php", data).then((response) => {
        console.log(response.data);
      });
    },
  },
  computed: {
    daysBeforeSaisie() {
      let today = new Date();
      let date = new Date(this.$store.state.selectedProject.dateSaisie);
      var diff = {}; // Initialisation du retour
      var tmp = date - today;

      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes

      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes

      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures

      tmp = Math.ceil(tmp / 24); // Nombre de jours restants
      diff.day = tmp;

      return diff.day;
    },
  },
};
</script>

<style scoped>
.b-avatar {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 6px, rgba(0, 0, 0, 0.2) 0px 1px 4px;
}

.b-card {
  height: 100%;
}

.custom-list {
  list-style-type: none;
  padding: 0;
}

.custom-list li {
  margin: 0;
  padding: 0;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100rem;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>