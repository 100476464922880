<template>
    <div>
     <div class="ml-1 mr-2 d-flex align-items-center">
          <div>
               <b-avatar 
                    v-if="$store.state.selectedAdminProject"  
                    :variant="$store.state.selectedAdminProject.imgsrc ? '' : 'info'"
                    icon="briefcase"
                    size="5rem"
                    class="p-2"
                    :src="$store.state.selectedAdminProject.imgsrc" />
               
                    <b-skeleton 
                    v-else
                    type="avatar" 
                    size="5em"
                    class="p-2 mx-auto"
               />
          </div>
          <div class="py-2 pl-3 w-50">
               <h5 v-if="$store.state.selectedAdminProject" >{{$store.state.selectedAdminProject.title}}</h5>
               <b-skeleton v-else width="30%"></b-skeleton>
          </div>
          <div class="ml-auto">
               <b-button  variant="info" @click="config = true" class="mr-1"><b-icon icon="gear-fill" scale="1"/><span class="d-none d-md-inline"> Configuration</span></b-button>
               <b-button  variant="primary" @click="$router.push('/adminProjets')"><b-icon icon="arrow-left-short" /><span class="d-none d-md-inline"> Retour</span></b-button>               
          </div>
     </div>
      <b-row>
        <b-col>
          <b-nav class="pb-2">
            <b-nav-item :to="'/adminProjets/' + $route.params.id + '/general'" :active="$route.name == 'General'"
              exact-active-class="activeNav">
              Général
            </b-nav-item>
            <b-nav-item :to="'/adminProjets/' + $route.params.id + '/progression'" :active="$route.name == 'Progression'"
              exact-active-class="activeNav">
              Progression
            </b-nav-item>
            <b-nav-item :to="'/adminProjets/' + $route.params.id + '/form'" :active="$route.name == 'Formulaire'"
              exact-active-class="activeNav">
              Formulaire
            </b-nav-item>
            <b-nav-item :to="'/adminProjets/' + $route.params.id + '/membres'" :active="$route.name == 'detailCollab'"
              exact-active-class="activeNav">
              Membres
            </b-nav-item>
          </b-nav>
          <transition name="fade" mode="out-in" @beforeLeave="beforeLeave" @enter="enter" @afterEnter="afterEnter">
            <router-view />
          </transition>
        </b-col>
      </b-row>
      <Modal v-model="config">
          <ProjetSideBar />
          <template v-slot:buttons>
               <b-button class="mr-2" variant="outline-danger" @click="config = false">Annuler</b-button>
               <b-button variant="outline-primary" @click="saveChanges">Sauvegarder</b-button>
          </template>
      </Modal>
    </div>
</template>
<script>
  import ProjetSideBar from '/src/components/ProjetManager/ProjetSideBar.vue'
  import Modal from '/src/components/Modal.vue'

  export default {
    name: "AdminProjet",
    components : {
      ProjetSideBar,
      Modal
    },
    data(){
          return {
               config: false
          }
    },
    mounted() {
      this.refresh();
    },
    methods: {
      refresh() {
        this.$store.dispatch("refreshSelectedAdminProject");
      },
      saveChanges() {
        this.$store.dispatch("saveAdminProjectChanges");
        this.config = false
      },
      beforeLeave(element) {
        this.prevHeight = getComputedStyle(element).height;
      },
      enter(element) {
        const { height } = getComputedStyle(element);

        element.style.height = this.prevHeight;

        setTimeout(() => {
          element.style.height = height;
        });
      },
      afterEnter(element) {
        element.style.height = "auto";
      },
    },
  };
</script>

<style scoped>
.activeNav{
  border-bottom: 2px solid rgb(187, 187, 231);
}

.b-avatar {
        border : 1px solid rgb(199, 196, 196);
        background-color: white
    }
</style>