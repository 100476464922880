<template>
  <div>
    <div v-if="aptitudeKey > -1">
      <b-breadcrumb>
        <b-breadcrumb-item>{{ $store.state.selectedProject.categories[catKey].nom }}</b-breadcrumb-item>
        <b-breadcrumb-item>{{ $store.state.selectedProject.categories[catKey].sousCategories[scatKey].nom }}</b-breadcrumb-item>
      </b-breadcrumb>
      <div v-for="(cat, i) in $store.state.selectedProject.categories" :key="i">
        <div v-for="(scat, y) in cat.sousCategories" :key="y">
          <div v-for="(apt, z) in scat.aptitudes" :key="apt.ID">
            <transition name="slidea" mode="out-in">
              <b-card-body
                v-if="z == aptitudeKey && y == scatKey && i == catKey"
              >
                <h3>{{ apt.nom }}</h3>
                <b-card-text>
                  {{ apt.description }}
                </b-card-text>
                <b-form @submit="submitApt">
                  <b-form-group
                    label="Votre avancée : "
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="selected"
                      :options="apt.type == 'Pratique' ? filteredOptionsPra : filteredOptionsTheo"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options"
                      required
                      stacked
                    ></b-form-radio-group>
                  </b-form-group>
                  <div class="mt-2 float-right">
                    <b-button type="submit" variant="success">Suivant</b-button>
                  </div>
                </b-form>
              </b-card-body>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center my-5 py-5">
      <h2>Saisie terminée!</h2>
      <b-button to="/projets" variant="primary" class="mt-5"
        >Retour projets</b-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Saisie",
  data() {
    return {
      test: false,
      optionsTheo: [
        { value: 0, text: "Rien fait" },
        { value: 1, text: "Lu/Expliqué une fois (en partie)" },
        { value: 2, text: "Lu/Expliqué une fois (totalité)" },
        { value: 3, text: "Compris/Connu en partie" },
        { value: 4, text: "Compris/Connu en totalité" },
        { value: 5, text: "Maîtrisé (Autonome)" },
      ],
      optionsPra: [
        { value: 0, text: "Ne sait pas encore ce que c’est" },
        { value: 1, text: "Expliqué" },
        { value: 2, text: "Vu (Double passif)" },
        { value: 3, text: "Réalisé avec accompagnement (Double Actif)" },
        { value: 4, text: "Réalisé sans aide (Double Actif)" },
        { value: 5, text: "Maîtrisé (Autonomie)" },
      ],
      selected: null,
      aptitudeKey: -1,
      catKey: 0,
      scatKey: 0,
      currentApt: {},
      back: {},
    };
  },
  methods: {
    selectNextAptitude() {
      let currentCat =
        this.$store.state.selectedProject.categories[this.catKey];
      let currentScat = currentCat.sousCategories[this.scatKey];
      this.currentApt = {};

      if (currentScat.aptitudes.length > this.aptitudeKey + 1)
        this.aptitudeKey++;
      else if (currentCat.sousCategories.length > this.scatKey + 1) {
        this.aptitudeKey = 0;
        this.scatKey++;
      } else if (
        this.$store.state.selectedProject.categories.length >
        this.catKey + 1
      ) {
        this.aptitudeKey = 0;
        this.scatKey = 0;
        this.catKey++;
      } else {
        this.submitAll();
      }
      if (this.aptitudeKey != -1) {
        this.currentApt =
          this.$store.state.selectedProject.categories[
            this.catKey
          ].sousCategories[this.scatKey].aptitudes[this.aptitudeKey];
        this.selected = this.currentApt.prev;

        if (this.selected == 5) {
          this.submitToBack();
          this.selectNextAptitude();
        }
      }
    },
    submitApt(event) {
      event.preventDefault();
      this.submitToBack();
      this.selected = null;
      this.selectNextAptitude();
    },
    submitToBack() {
      this.back.responses.push({
        aptitudeID: this.currentApt.ID,
        response: this.selected,
      });
    },
    submitAll() {
      this.aptitudeKey = -1;
      let data = new FormData();
      data.set("back", JSON.stringify(this.back));
      this.axios.post("/send.php", data);
    },
  },
  computed: {
    filteredOptionsTheo() {
      return this.optionsTheo.filter((option) => {
        if (
          option.value <
          this.$store.state.selectedProject.categories[this.catKey]
            .sousCategories[this.scatKey].aptitudes[this.aptitudeKey].prev
        )
          option.disabled = true;
        else option.disabled = false;
        return option;
      });
    },
    filteredOptionsPra() {
      return this.optionsPra.filter((option) => {
        if (
          option.value <
          this.$store.state.selectedProject.categories[this.catKey]
            .sousCategories[this.scatKey].aptitudes[this.aptitudeKey].prev
        )
          option.disabled = true;
        else option.disabled = false;
        return option;
      });
    },
  },
  mounted() {
    this.back = {
      sessionID: this.$store.state.selectedProject.sessionID,
      userID: this.$store.state.user.id,
      projetID: this.$store.state.selectedProject.ID,
      responses: [],
    };
    this.selectNextAptitude();
  },
};
</script>

<style scoped>
.b-avatar {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 6px, rgba(0, 0, 0, 0.2) 0px 1px 4px;
}

.b-overlay {
  height: 100%;
}

.b-card {
  height: 100%;
}

.nav-link {
  padding: 0.5rem 0.75rem 0.25rem 0.75rem;
}

.slidea-enter-active,
.slidea-leave-active {
  transition: all 0.25s ease;
}

.slidea-enter-active {
  transition-delay: 0.25s;
}

.slidea-enter {
  opacity: 0;
  transform: translateX(10em);
}

.slidea-leave-to {
  opacity: 0;
  transform: translateX(-15em);
}

.slidea-enter-from,
.slidea-leave-to {
  max-height: 0px;
}
</style>