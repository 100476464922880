<template>
     <div>
          <div v-if="label" class="label">
               {{label}}
          </div>
          <select v-if="type == 'select'" class="my-input" :class="size ? size : 'lg'" :disabled="disabled">
               <option v-for="(option, index) in options" :key="index" :value="option" :selected="option == value">
                    {{option}}</option>
          </select>
          <textarea v-else-if="type == 'textarea'" class="my-input textarea" :value="value"
               v-on:input="updateValue($event.target.value)" v-on:keyup.enter="keyEnter" />
          <div v-else-if="type == 'number-spin'">
               <b-row>
                    <b-col cols="2">
                         <b-button @click="updateValue(($event.target.value)--)">-</b-button>
                    </b-col>
                    <b-col>
                         <input class="my-input" :class="size ? size : 'lg'" type="number" :value="value"
                              :required="required" v-on:input="updateValue($event.target.value)"
                              v-on:keyup.enter="keyEnter" :disabled="disabled" />
                    </b-col>
                    <b-col cols="2">
                         <b-button @click="updateValue(($event.target.value)++)">+</b-button>
                    </b-col>
               </b-row>
          </div>
          <input v-else 
               class="my-input" 
               :class="size ? size : 'lg'" 
               :type="type" 
               :placeholder="placeholder"
               :value="value"
               :required="required"
               :min="min"
               :max="max"
               v-on:input="updateValue($event.target.value)"
               v-on:keyup.enter="keyEnter"
               :disabled="disabled" 
          />
     </div>
</template>
  
<script>
export default {
     name: "Input",
     props: [
          "value",
          "type",
          "required",
          "label",
          "options",
          "size",
          "disabled",
          "min",
          "max",
          "placeholder"
     ],
     methods: {
          updateValue: function (value) {
               this.$emit('input', value)
          },
          keyEnter() {
               this.$emit('keyEnter', true)
          }
     }
}
</script>
  
<style scoped>
.label {
     font-weight: 600;
     margin: 12px 1px;
     color: rgba(0, 0, 0, 0.75)
}

.my-input {
     width: 100%;
     border: none;
     background-color: rgba(0, 0, 100, 0.03);
     border-radius: 7px;
     outline: none;
     box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}

.my-input:disabled {
     background-color: white;
     font-weight: 600;
     box-shadow: none
}

.my-input:focus {
     border: 1px solid rgba(0, 0, 50, 0.2);
}

.lg {
     height: 3rem;
     padding: 0 20px;
}

.sm {
     height: 2.25rem;
     padding: 0 10px;
}

.textarea {
     height: 10rem;
     padding: 10px 15px;
     resize: none;
}
</style>
  