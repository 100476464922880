<template>
    <div class="card-stat d-flex flex-column justify-content-between
">
        <div>
            <div class="value">{{value.length}}</div>
            <div class="text">{{title}}</div>
            <div v-if="date" class="date">{{dateLabel}} <strong>{{date}}</strong></div>
        </div>
        <div v-if="value.length">
            <b-avatar-group size="50px" class="text-center" >
                <b-avatar v-for="(collab, index) in firstCollabs" :key="collab.ID" :text="collab.Prenom[0] + collab.Nom[0]"
                    v-b-tooltip.hover="collab.Prenom + ' ' + collab.Nom" :variant="index % 2 ? 'info' : 'primary'">
                </b-avatar>
                <b-avatar v-if="more.length" variant="dark" id="tooltip-target-1">+{{more.length}}</b-avatar>
            </b-avatar-group>
            <b-tooltip target="tooltip-target-1" triggers="hover">
                <ul>
                    <li v-for="collab in more" :key="collab.ID">{{collab.Prenom + ' ' + collab.Nom}}</li>
                </ul>
            </b-tooltip>
        </div>
    </div>
</template>
  
  <script>
  export default {
    name: "StatsCard",
    props : {
        value : Array,
        title : String,
        date: String,
        dateLabel : String
    },
    data(){
        return  {
            numberCollab : 4
        }
    },
    computed : {
        firstCollabs(){
            let sliceValue = + this.value.length <= 5 ? 5 : this.numberCollab
            return this.value.slice(0, sliceValue)
        },
        more(){
            if(this.value.length <= 5) return []
            return this.value.slice(- (this.value.length - this.numberCollab))
        }
    }
  }
  </script>
  
  <style scoped>
   .card-stat{
    border-radius: 10px;
    padding: 10px 15px;
    margin-top : 10px;
    cursor: pointer;
    width: 15rem;
    height: 13rem;
  }

  .card-stat .text{
    font-weight : 600;
    font-size: 0.8em;
    color: rgb(239, 233, 233)
  }
  .card-stat .date{
    font-size: 0.8em;
    color: rgb(233, 237, 247)
  }

  .card-stat .value{
    font-size: 34px;
    font-weight : 600;
    color: white
  }

  </style>
  