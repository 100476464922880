<template>
    <div v-if="$store.state.selectedProject">
      <div v-if="!view">
        <div>
          <ul class="custom-list">
            <li
              v-for="(cat, index) in $store.state.selectedProject.categories"
              :key="index"
              class="p-0 py-2"
              :class="
                index != $store.state.selectedProject.categories - 1
                  ? 'border-bottom'
                  : ''
              "
            >
              <b-container>
                <b-row v-on:click="cat.toggle = !cat.toggle">
                  <b-col cols="7">
                    <span>{{ cat.nom }}</span>
                  </b-col>
                  <b-col cols="4">
                    <b-progress height="25px" class="mt-2">
                      <b-progress-bar
                        striped
                        :value="cat.progress"
                        :label="`${cat.progress}%`"
                        :variant="progressColor(cat.progress)"
                      ></b-progress-bar>
                    </b-progress>
                  </b-col>
                  <b-col cols="1">
                    <b-icon
                      class="float-right mr-2 mt-1"
                      :icon="cat.toggle ? 'chevron-down' : 'chevron-left'"
                      scale="1.2"
                      variant="info"
                    />
                  </b-col>
                </b-row>
                <b-row v-if="cat.toggle">
                  <b-col cols="12">
                      <ul v-if="cat.toggle" class="mt-2 ml-4 custom-list">
                        <li
                          v-for="(scat, index) in cat.sousCategories"
                          :key="index"
                          class="p-0 py-2 border-top"
                        >
                          <b-container>
                            <b-row v-on:click="scat.toggle = !scat.toggle">
                              <b-col cols="7">
                                <span>{{ scat.nom }}</span>
                              </b-col>
                              <b-col cols="4">
                                <b-progress height="25px">
                                  <b-progress-bar
                                    striped
                                    :value="scat.progress"
                                    :label="`${scat.progress}%`"
                                    :variant="progressColor(scat.progress)"
                                  ></b-progress-bar>
                                </b-progress>
                              </b-col>
                              <b-col cols="1">
                                <b-icon
                                  class="float-right mr-2 mt-1"
                                  :icon="
                                    scat.toggle
                                      ? 'chevron-down'
                                      : 'chevron-left'
                                  "
                                  scale="1.2"
                                  variant="info"
                                />
                              </b-col>
                            </b-row>
                            <b-row v-if="scat.toggle">
                              <b-col cols="12">
                                  <ul
                                    class="mt-2 ml-4 custom-list"
                                    v-if="scat.toggle"
                                  >
                                    <li
                                      v-for="(apt, z) in scat.aptitudes"
                                      :key="z"
                                      class="py-2 border-top"
                                    >
                                      <b-container>
                                        <b-row>
                                          <b-col cols="7">
                                            {{ apt.nom }}</b-col
                                          ><b-col cols="3">
                                            <b-progress height="30px">
                                              <b-progress-bar
                                                striped
                                                :value="(apt.prev / 5) * 100"
                                                :label="`${
                                                  (apt.prev / 5) * 100
                                                }%`"
                                                :variant="
                                                  progressColor(
                                                    (apt.prev / 5) * 100
                                                  )
                                                "
                                              ></b-progress-bar>
                                            </b-progress>
                                          </b-col>
                                          <b-col cols="2">
                                            <b-button
                                              class="float-right"
                                              size="sm"
                                              variant="outline-primary"
                                              @click="viewApt(apt)"
                                              pill
                                              ><b-icon icon="eye"
                                            /></b-button>
                                          </b-col>
                                        </b-row>
                                      </b-container>
                                    </li>
                                  </ul>
                              </b-col>
                            </b-row>
                          </b-container>
                        </li>
                      </ul>
                  </b-col>
                </b-row>
              </b-container>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="view && selectedApt">
        <div class="py-5 px-5">
          <h3>{{ selectedApt.nom }}</h3>
          <p class="py-3">
            {{ selectedApt.description }}
          </p>
          <b-progress height="25px" class="my-3">
            <b-progress-bar
              striped
              :value="(selectedApt.prev / 5) * 100"
              :label="`${(selectedApt.prev / 5) * 100}%`"
              :variant="progressColor((selectedApt.prev / 5) * 100)"
            ></b-progress-bar>
          </b-progress>
          <b-form-group
            v-if="edit"
            label="Avancée : "
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-1"
              v-model="selectedAptUptadte"
              :options="selectedApt.type == 'Pratique' ? optionsPra : optionsTheo"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
              required
              stacked
            ></b-form-radio-group>
          </b-form-group>
          <span v-else>Réponse : {{selectedApt.type == 'Pratique' ? optionsPra[selectedApt.prev].text :  optionsTheo[selectedApt.prev].text}}</span>
          <div class="py-5 float-right">
            <b-button
              @click="
                edit = false;
                view = false;
              "
              variant="primary"
              class="mr-2"
              >Retour</b-button
            >
            <b-button v-if="edit" @click="saveApt" variant="danger"
            class="mr-2">Annuler</b-button>
            
            <b-button v-if="edit" @click="saveApt" variant="success"
              >Mettre à jour</b-button
            >
            <b-button v-if="!edit && selectedApt.prevID" variant="warning" @click="editApt"
              ><b-icon icon="pencil" />Editer la progreession</b-button
            >
          </div>
        </div>
      </div>
    </div>
</template>
<script>

export default {
  name: "detailCollab",
  data() {
    return {
      optionsTheo: [
        { value: 0, text: "Rien fait" },
        { value: 1, text: "Lu/Expliqué une fois (en partie)" },
        { value: 2, text: "Lu/Expliqué une fois (totalité)" },
        { value: 3, text: "Compris/Connu en partie" },
        { value: 4, text: "Compris/Connu en totalité" },
        { value: 5, text: "Maîtrisé (Autonome)" },
      ],
      optionsPra: [
        { value: 0, text: "Ne sait pas encore ce que c’est" },
        { value: 1, text: "Expliqué" },
        { value: 2, text: "Vu (Double passif)" },
        { value: 3, text: "Réalisé avec accompagnement (Double Actif)" },
        { value: 4, text: "Réalisé sans aide (Double Actif)" },
        { value: 5, text: "Maîtrisé (Autonomie)" },
      ],
      edit: false,
      view: false,
      selectedApt: null,
      selectedAptUptadte: 0,
    };
  },
  mounted() {
    console.log(this.$store.state.selectedCollabID);
    this.$store.dispatch("refreshSelectCollabSession");
  },
  methods: {
    progressColor(value) {
      if (value <= 50) return "danger";
      else if (value <= 60) return "warning";
      else if (value <= 70) return "info";
      else if (value <= 80) return "primary";
      return "success";
    },
    viewApt(apt) {
      this.selectedApt = apt;
      this.view = true;
    },
    editApt() {
      this.selectedAptUptadte = this.selectedApt.prev;
      this.edit = true;
    },
    saveApt() {
      this.selectedApt.prev = this.selectedAptUptadte;
      this.edit = false;
      let data = new FormData();
      data.set(
        "back",
        JSON.stringify({
          ID: this.selectedApt.prevID,
          response: this.selectedApt.prev,
        })
      );
      this.axios.post("/update.php", data).then((response) => {
        console.log(response.data);
      });
    },
    showAll() {
      for (let cat of this.$store.state.selectedProject.categories) {
        for (let scat of cat.sousCategories) scat.toggle = true;
        cat.toggle = true;
      }
    },
    hideAll() {
      for (let cat of this.$store.state.selectedProject.categories) {
        for (let scat of cat.sousCategories) scat.toggle = false;
        cat.toggle = false;
      }
    },
  },
};
</script>

<style scoped>
.custom-list {
  list-style-type: none;
  padding: 0;
}

.custom-list li {
  margin: 0;
  padding: 0;
}
</style>