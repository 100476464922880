<template>
  <div class="myclass">
    <b-row>
      <b-col v-if="title">
        <b-icon class="mr-2 point" :icon="hide ? 'chevron-right' : 'chevron-down'" @click="hide = !hide" variant="info"/>
        <span class="title">{{title}}</span>
        <b-badge v-if="badge" pill variant="info" class="ml-2">{{badge}}</b-badge>
      </b-col>
      <div class="mr-3">
        <slot name="header-right"></slot>
      </div>
    </b-row>
    <div class="pt-4 px-3"  v-if="!hide">
      <slot ></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupContainer",
  props : [
    "title",
    "badge",
  ],
  data(){
    return {
      hide : false
    }
  },
}
</script>

<style scoped>
.myclass{
  border : 1px solid rgb(250, 240, 240);
  border-radius : 10px;
  background-color: white;
  padding: 1.25em 1em;
  margin: 0 0 0.75em 0;
}

.title{
  color: rgb(151, 148, 180);
  font-size : 15px;
  text-overflow: ellipsis;
  font-weight: 600;
}
</style>
