<template>
  <div>
    <div class="text-center p-5">
      <span class="text">Opps...</span>
      <h3 id="number">404</h3>
      <span class="text">Not found</span>
    </div>
    <b-button :to="$router.go(-1)" class="mt-5" variant="primary"
      >Retour</b-button
    >
  </div>
</template>

<script>
export default {
  name: "notFound"
};
</script>

<style scoped>
#number {
  font: 600 15vmin "Consolas";
  color: orange;
  letter-spacing: 3vmin;
  text-shadow: 0 0 0.1em #000644, 0 0 0.01em #000644;
}

.text {
  font: 600 20vmin "Consolas";
  font-size: 2rem;
  color: #000644;
  letter-spacing: 0;
}
</style>